import { createApp } from 'vue'
import App from './App.vue'
// import Stats from "stats.js";
import store from "./store/global-store";

// import VueRouter from 'vue-router'
import router from "./router/router";
// import VueCookies from 'vue-cookies'

// import store from "./store/global-store";
// import VueRouter from "vue-router";
// Vue.use(VueRouter);
// import Stats from "stats.js";

import Vue3TouchEvents from "vue3-touch-events";


// https://stackoverflow.com/questions/63471824/vue-js-3-event-bus
import mitt from 'mitt';
const emitter = mitt();

import 'normalize.css'; 
import "./css/base.scss";
import "./css/style.scss";

import "./css/transitions.scss";
import "./css/typo.scss";

// import VueVideoPlayer from '@videojs-player/vue'
// import 'video.js/dist/video-js.css'

// import VuePlyr from '@skjnldsv/vue-plyr'
// // eslint-disable-next-line import/no-unresolved,n/no-missing-import
// import '@skjnldsv/vue-plyr/dist/vue-plyr.css'

// import VuePlyr from 'vue-plyr'
// import 'vue-plyr/dist/vue-plyr.css'

// let stats = new Stats();
// initStats();

// import Vue3VideoPlayer from '@cloudgeek/vue3-video-player'
// import '@cloudgeek/vue3-video-player/dist/vue3-video-player.css'

//import VuePlyr from '@skjnldsv/vue-plyr'
//import '@skjnldsv/vue-plyr/dist/vue-plyr.css'

import VuePlyr from 'vue-plyr'
import 'vue-plyr/dist/vue-plyr.css'
import HelperPlugin from "./plugins/HelperPlugin";


// const plugins = {
//   install() {
//       Vue.helpers = helpers;
//       Vue.prototype.$helpers = helpers;
//   }
// }
// Vue.use(plugins);


// 5. Create and mount the root instance.
const app = createApp(App)
// Make sure to _use_ the router instance to make the
// whole app router-aware.
app.config.globalProperties.emitter = emitter;
app.config.devtools = true;

app.use(store)
app.use(router)
app.use(HelperPlugin)
// app.use(VueVideoPlayer)
// app.use(VuePlyr)

// app.use(VueCookies, { expire: '7d'})

// import { TroisJSVuePlugin } from 'troisjs';
app.use(VuePlyr, {plyr: {} })

app.use(Vue3TouchEvents);

// app.use(Vue3VideoPlayer, {
//   lang: 'zh-CN'
//   // lang: 'en-us'
// })

app.mount('#app')


// stats //////////////////////////////////////////////////////////////
// function initStats() {
//     // console.log("initStats");
//     // console.log("initStats debug", store.state.debug);
//     // if (store.state.debug == true){
//        stats.setMode = 0;
//        // stats.domElement.style.cssText = "position:absolute;top:0px;right:0px;";
//       //  stats.dom.style.height = "200px";
//        document.body.appendChild(stats.domElement);
//     // }
//   }
//   function animateStats() {
//     stats.begin()
//     // monitored code goes here
//     stats.end()
//     requestAnimationFrame(animateStats)
//   }
//   requestAnimationFrame(animateStats)
  // stats end ////////////////////////////////////////////////////////////