<template>
  
  <div class="bgColor" :style="bgColorStyle()"></div>

  <div class="content" v-if="uiLoaded==true&&imagesLoaded==true">

      <div class="header">  
        <Transition name="headlinefade"  mode="out-in" appear>
            <h3 v-html="getHeadline()" :key="getHeadline()"></h3>
        </Transition>
        <div class="deko__line"></div>  
      </div>

      <Footer></Footer>
      
      <!--:key="route.path" -->
      <router-view  v-slot="{ Component }">  
        <transition name="slidefade" appear mode="out-in" >
            <component :is="Component" class="animated" :key="route.path.split('/')[0]" />
        </transition>
      </router-view>

  </div>
 
  <transition name="fadepage" mode="out-in" appear key="store.state.kidsBubble">
      <KidsBubble v-if="store.state.kidsBubble==true" @click.passive="openKidsMenu()"></KidsBubble>
      <!--  -->
  </transition>

  <!-- <transition name="fadepage" mode="out-in" appear key="store.state.kidsGames">
      <KidsGames v-if="store.state.kidsGames==true" ></KidsGames>  
  </transition> -->

  <transition name="fadesplash" mode="out-in" >
      <blSplash  :loadtext="loadText" v-if="imagesLoaded==false"></blSplash>
  </transition>

  <blDemoOverlay v-if="store.state.debug==true"></blDemoOverlay>

  <!-- <blLayoutGrid></blLayoutGrid> -->
  <!-- <blSystemInformation /> -->

</template>



<script>
import Footer from './components/Footer.vue'

// import blSystemInformation from './components/blSystemInformation.vue'
import blSplash from './components/blSplash.vue'
import KidsBubble from './components/KidsBubble.vue'
import blDemoOverlay from './components/blDemoOverlay.vue'
// import KidsGames from './components/KidsGames.vue'
// import blLayoutGrid from './components/blLayoutGrid_ipad_768x1024.vue'
import { onMounted, ref } from 'vue'; // ref, onMounted, computed, inject
import { useRouter, useRoute } from 'vue-router'  // 
import {useStore} from 'vuex';
import useEmitter from '@/composables/useEmitter';
import { publicPath } from '../vue.config';




export default {
  name: 'App',
  
  components: {
    Footer,
    // blSystemInformation
    blSplash,
    KidsBubble,
    blDemoOverlay,
    // KidsGames,
    // blLayoutGrid
  },
  
  setup(){
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const emitter = useEmitter();
    // const $cookies = inject('$cookies');

    let uiLoaded = ref(false);
    // let texteLoaded = ref(false);
    let contentLoaded = ref(false);
    // let zitateLoaded = ref(false);

    let errorLoads =[];
    let toLoads = [];
    let loadText = ref("");
    let imagesLoaded = ref(false)

    let timeoutID = 0;


    const getDebug= () => {
      return store.state.debug;
    }

    const goHome = () => {
          // console.log("goHome app");

          store.state.page = 0;
          // store.state.book = -1;
          // store.state.showGuide = false;
          store.state.impressum = false;
          store.state.hideBerge = false;
          store.state.language = "de";
          store.state.kids = false;
          store.state.kidsMenu = -1;
          store.state.kidsMenuGame = 0;
          store.state.kidsBubble = true;
          store.state.kidsGames = false;
          router.push({ name: 'homepage',  props: true})  // params: { "bookID": ""},
          // router.push("/").catch(()=>{});
    }

    // IMAGE LOADING METHODS
    const checkLoadingFinishedOK= (url) => { // (url){
        // console.log("checkLoadingFinishedOK")
        let index = toLoads.indexOf(url);
        loadText.value = url;
        if (index > -1) {
          toLoads.splice(index, 1);
        }
        // console.log(this.toLoads);
        checkLoadingFinished();
        // console.log("OK");
    }

    const checkLoadingFinishedNOOK = (url) => { //(url){
        var index = toLoads.indexOf(url);
        if (index > -1) {
          toLoads.splice(index, 1);
        }
        // console.log(this.toLoads);
        errorLoads.push(url);
        checkLoadingFinished();
        // console.log("NO OK");
    }

    // eslint-disable-next-line no-unused-vars
    const refreshTimeout = (event) => {
        // console.log("refreshTimeout", event)
        clearTimeout(timeoutID);
        timeoutID = setTimeout (()=> {
          // console.log("timeoutID fired");
          goHome();
        }, 300 * 1000);
    }

    const checkLoadingFinished = () => {
        if (toLoads.length==0){
          // loaded.value = true;
          if (errorLoads.length == 0){
              // store.dispatch('setAppLoaded');  // direkt freischalten des splash screen
          } else {
            // headertext= "Fehlende Medien";
            uiLoaded.value = true; // button auf dem splash screen anzeigen
            loadText.value = errorLoads;
          }
          // console.log ("END OF LOADING");

          imagesLoaded.value = true;
          // console.log("uiLoaded", uiLoaded.value)
        }
    }

    const loadOneImage = (url) => { 
        // console.log(" **** url laden versuch: "+ url)
        return new Promise(function(resolve, reject) {
          let img = new Image();
          img.onload = function() {
            // console.log("loaded:"+ url)
            checkLoadingFinishedOK(url);
            resolve(url);
          }
          img.onerror = function() {
            console.log("konnte das nicht laden:"+ url)
            checkLoadingFinishedNOOK(url);
            reject(url);
          }
          img.src = url;
        })
    }

    const openKidsMenu = () => {
            // store.state.mainMenu = 0;
            // store.state.page = 1;
            // store.state.hideBerge = true;
            store.state.kidsBubble = false;
            store.state.kidsGames = true;
            // router.push({ path: 'homepage/empty',  props: true})  // params: { "bookID": ""},

            // store.state.kids = true;
            // store.state.kidsMenu = -1;
            // router.push({ name: 'kidsmenupage', props: true} )
            // router.push({ path: "/kidsmenupage" + "/" })

            // if (store.state.content.menus[index].intro.route=="intropage" ) {
            //      router.push({ name: 'intropage', props: true} )  // params: { "bookID": "2"}, 
            // // } else if (store.state.content.menus[index].intro.route=="videopage" ) {
            // //      router.push({ name: 'videopage', props: true} )  // params: { "bookID": "2"}, 
            // // } else if (store.state.content.menus[index].intro.route=="videopageplyr" ) {
            // //      router.push({ name: 'videopageplyr', props: true} )  // params: { "bookID": "2"}, 
            // } else  {
            //      router.push({ name: store.state.content.menus[index].intro.route, props: true} )  // params: { "bookID": "2"}, 
            // }
            
            // if ( index > 0 ){ // es handelt sich um eins der nicht bibel bücher
            //      loadBookTexte(index);
            // }
            // console.log("contentRoom", store.state.roomId );
            // router.push({ name: "rundgangPage", params: { roomId: store.state.roomId, language: store.state.language}, props: true})
      }

      const bgColorStyle = () => {
        //  console.log(bgColorStyle);
        //  if (store.state.kids==true){
         if (store.state.kidsMenu==true){
            return {
              opacity: 1.0,
              transitionDelay: '0.5s',
            }
         } else {
          return {
              opacity: 0.0,
              transitionDelay: '0.3s',
            }
         }
      }


    const contentLoad = () => {
      // console.log("contentLoad", texteLoaded.value)
      // console.log("contentUi debug", contentUi.value.debug)
      if (process.env.NODE_ENV === "production") {
        var base_url = window.location.origin + "/" + publicPath   // "https://www.bluelemon.de/demo/AL/VB_v1/" 
      } else {
        base_url =  window.location.origin + "/" + publicPath //   
      }
      
      // console.log("base_url", base_url + 'content.json')

      // fetch('./static/contentUi.json', { method: 'get', headers: { 'content-type': 'application/json' } } )
      fetch(base_url + 'content.json', { method: 'get', headers: { 'content-type': 'application/json' } } )
          .then(res => {
              if (!res.ok) {
                // console.log("loaded NOOK")
                // create error instance with HTTP status text
                const error = new Error(res.statusText);
                error.json = res.json();
                throw error;
              }
              return res.json();
          })
          .then(json => {           
              // console.log("json", json)
              store.state.content = json;

              // for(var i = 0; i < store.state.content.books; i++) {
              //   toLoads.push(base_url + store.state.content.books[i].image);     
              // }
              // toLoads.push(base_url + "img-vorschau/bibel-preload.jpg");
              // toLoads.push(base_url + "images/reise.jpg");

              contentLoaded.value = true;
              if (uiLoaded.value == false){
                // console.log("ui laden init")
                uiLoad();
              }

          })
          .catch(err => {
              console.log("error", err)
              const error = new Error();
              error.value = err;
              if (err.json) {
                return err.json.then(json => {
                  error.value.message = json.message;
                });
              }
          })
          .then(() => {
            contentLoaded.value = true;
          });


      // fetch(base_url + 'bibel-texte.json', { method: 'get', headers: { 'content-type': 'application/json' } } )
      //     .then(res => {
      //         if (!res.ok) {
      //           console.log("loaded live content NOOK")
      //           console.log(res);
      //           // create error instance with HTTP status text
      //           const error = new Error(res.statusText);
      //           error.json = res.json();
      //           throw error;
      //         }
      //         return res.json();
      //     })
      //     .then(json => {

      //         store.state.bibelTexte = json;
      //         // console.log(store.state.bibelTexte);
      //         texteLoaded.value = true;

      //     })
      //     .catch(err => {
      //         console.log("error", err)
      //         const error = new Error();
      //         error.value = err;
      //         if (err.json) {
      //           return err.json.then(json => {
      //             error.value.message = json.message;
      //           });
      //         }
      //     })
      //     .then(() => {
      //       texteLoaded.value = true;
      //     });


      // fetch('https://app.museum-abtei-liesborn.de/wp-json/wp/v2/zitat?_embed&per_page=100&page=1', { method: 'get', headers: { 'content-type': 'application/json' } } )
      // fetch(base_url + 'zitate.json', { method: 'get', headers: { 'content-type': 'application/json' } } )
      
      //     .then(res => {
      //         if (!res.ok) {
      //           console.log("loaded live content NOOK")
      //           console.log(res);
      //           // create error instance with HTTP status text
      //           const error = new Error(res.statusText);
      //           error.json = res.json();
      //           throw error;
      //         }
      //         return res.json();
      //     })
      //     .then(json => {
      //         // console.log("zitate content loaded")
      //         // console.log("zitate json", json)

      //         // const sortAlphaNum = (a, b) => a.slug.localeCompare(b.slug, 'en', { numeric: true })
              
      //         // // console.log(ary.sort(sortAlphaNum))
      //         // // const sortAlphaNum = (a, b) => a.localeCompare(b, 'en', { numeric: true })
      //         // // const newSort = orderBy(json,['slug'],'asc');
              
      //         // const newSort = json.sort(sortAlphaNum)
      //         // console.log("newSort", newSort)
              
      //         // contentZitate.value = newSort;

      //         // console.log("contentZitate", contentZitate)
      //         store.state.contentZitate = json;
      //         zitateLoaded.value = true;

      //     })
      //     .catch(err => {
      //         console.log("error", err)
      //         const error = new Error();
      //         error.value = err;
      //         if (err.json) {
      //           return err.json.then(json => {
      //             error.value.message = json.message;
      //           });
      //         }
      //     })
      //     .then(() => {
      //         zitateLoaded.value = true;
      //     });

    }

    const uiLoad = () => {
      // console.log("uiLoad", uiLoaded.value)
      // console.log("contentUi debug", contentUi.value.debug)
      if (process.env.NODE_ENV === "production"){
        // var base_url = "https://www.bluelemon.de/demo/AL/mediaguide_v3/" // "." + publicPath
        var base_url = window.location.origin + "/" + publicPath  // "/"  + "http://127.0.0.1:8080/" // "https://www.bluelemon.de/demo/AL/VB_v1/"// "." + publicPath
        // var base_url = "https://app.museum-abtei-liesborn.de/" // "." + publicPath
      } else {
        base_url = window.location.origin + "/" + publicPath // "/"
        // base_url =  "." + publicPath
      }
      
      // console.log("base_url", base_url + 'contentUi.json')
      fetch(base_url + 'contentUi.json', { method: 'get', headers: { 'content-type': 'application/json' } } )
          .then(res => {
              if (!res.ok) {
                console.log("loaded NOOK")
                // create error instance with HTTP status text
                const error = new Error(res.statusText);
                error.json = res.json();
                throw error;
              }
              return res.json();
          })
          .then(json => {
              // console.log("loaded")
              // console.log("json", json)
              // contentUi.value = json;
              store.state.contentUi = json;
              store.state.debug = json.debug;
              // console.log("contentUi", contentUi.value)
              toLoads.push(base_url + "images/bluelemon_logo.jpg");
              
              // for (var b = 0; b < store.state.content.books.length; b++) {
              //   // console.log  (b)
              //   for(var i = 1; i <= store.state.content.books[b].menu[0].pages; i++) {
              //     // console.log (i)
              //     let path = base_url + store.state.content.books[b].menu[0].path;                  
              //     toLoads.push(path.replace("/./", "/") + i +'.jpg');     
              //     // toLoads.push(base_url + 'bibel/954/'+i+'.jpg');     
              //   }
              // }

              // loadOneImage(imageArray[0])
              var promises = toLoads.map(loadOneImage);
              Promise.all(promises.map(p => p.catch(e => e)));
              // checkCookies()

              uiLoaded.value = true;
              imagesLoaded.value = false;

          })
          .catch(err => {
              console.log("error", err)
              const error = new Error();
              error.value = err;
              if (err.json) {
                return err.json.then(json => {
                  error.value.message = json.message;
                });
              }
          })
          .then(() => {
            uiLoaded.value = true;
          });
    }

    const getHeadline = () => {
      // console.log("headline")
      if (store.state.kidsMenu == 1){
          // console.log("headline kids")
          return store.state.content.kids.titelshort[store.state.language];
      } else {
        // console.log("headline no kids")
          if (store.state.page == 0){
            // console.log("headline no kids")
            return store.state.content.name[store.state.language];
          } else if (store.state.page >= 1 && store.state.impressum==false){
            // console.log("headline no kids")
            return store.state.content.menus[store.state.mainMenu].titelshort[store.state.language];
          } else if (store.state.page == 1 && store.state.impressum==true){
            // console.log("headline no kids")
            return store.state.content.name[store.state.language];
          }
      }
      
    }

    onMounted(() => {
      // console.log("mounted start");

      // document.querySelector("meta[name=viewport]").setAttribute('content', 'width=device-width, initial-scale='+(1/window.devicePixelRatio));

      window.addEventListener('keydown', (e) => {
            if(e.key =="d"){
                //  this.$store.dispatch('switchDebug'); 
                store.state.debug = !store.state.debug;
                // console.log("store.state.debug", store.state.debug);
                //  this.$store.state.debug = !this.$store.state.debug //  ('switchDebug'); 
                // } else if (e.key =="g"){
                //   this.switchVisible(); 
                // } else if (e.key =="t"){
                //   clearTimeout(refreshTimeoutId);
                //   store.dispatch('setAppTimeout', true);           
            }
      });
      
      // console.log("route.path", route.path);
      goHome();
      
      if (contentLoaded.value == false){
          // console.log("content laden init")
          contentLoad();
      }
      // eslint-disable-next-line no-unused-vars
      emitter.on("GO_HOME", message => {
          // console.log("GO HOME in APP", message)
          store.state.roomId = 0;
          store.state.roomPageId = 1;
          store.state.showRundgang = false;
          router.push("/").catch(()=>{});
          // goHome()
      })
      // eslint-disable-next-line no-unused-vars
      emitter.on("INFO_CLOSE", message => {
          // console.log("mediaGuide", message)
          store.state.showGuide = false;
      })

      // console.log("mounted end");
      refreshTimeout();
      // document.addEventListener('mouseDown', refreshTimeout, {passive: true});
      document.addEventListener('touchstart', refreshTimeout, {passive: true});

      // emitter.on("GO_UP", message => {
      //     console.log("GO UP in APP", message)
      // })

    })

    return {

      uiLoaded,
      contentLoaded,
      route,
      router,
      store,
      imagesLoaded,
      loadText,

      goHome,
      getDebug,
      getHeadline,
      openKidsMenu,
      bgColorStyle,

    }

  }

}
</script>



<style lang="scss" scoped>
@import "css/variables.scss";

#app {
  font-family: FontLight, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  position: absolute;
  width: 1920px;
  height: 1080px;
  background-color: #000000;
}
.header {
  position: absolute;
  left: 50px;
  top: 40px;
}

button {
  background-color: #ffe601; 
  border-radius: 8px;
  border: none;
  color: rgb(0, 0, 0);
  margin: 0.4rem;
  padding: 0.8rem 1.6rem;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1.0rem;
}

.animated {
  transition: all 0.7s;
  // display: flex;
  // width: 100%;
}

// .spacer {
//   height: 20px;
// }

.headlinefade-enter-from,
.headlinefade-leave-to {
  transition: opacity 0.5s ease-out 0.0s;
  opacity: 0;
  position: relative;
}

.headlinefade-leave-from,
.headlinefade-enter-to {
  transition: opacity 0.5s ease-out 0.0s;
  // opacity: 1;
}

.bgColor {
  position: absolute;
  width: 1920px;
  height: 1080px;
  background-color: $purple;
  transition: 0.6s opacity ease 0s;
}

:root {
    --swiper-theme-color: #000000;
}
</style>