<template>

    <div class="video-bg">
        <div class="bg">
          <img src="/salzpfanne/PfanneUeberblendung.jpg" alt="">
        </div>

        <video class="videoContainer" autoplay  @ended="onVideoEnded">
            <source src="/salzpfanne/PfanneUeberblendung.mp4" type="video/mp4"/>
        </video>
    </div>

</template>



<script>


export default {

    name: "VideoTransition",

    emits: ["emit-videoended"],

    setup(props, { emit } ) {

        const onVideoEnded = () => {
            // console.log("onVideoEnded");
            emit("emit-videoended", "hallo");
        }

        return {
            props,
            onVideoEnded,
        }
    }

}

</script>




<style scoped>

.video-bg  {
  position: absolute;
  width: 1840px;
  height: 1035px;
  z-index: 201;
}

.bg {
  position: absolute;
  width: 1840px;
  height: 1035px;
  z-index: 202;
}

.videoContainer {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 1840;
    height: 1035;
    z-index: 202;
}
</style>