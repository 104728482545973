<template>
    
    <div class="splash-container" >
        
            <div class="zwei-drittel">
                <div style="width: 30%">
                    <div class="logo"></div>
                    <!-- <div class="logo-svg-calc"></div> -->
                </div>
            </div>
           
            <div class="ein-drittel">
               <p>{{ loadtext }}</p>
            </div>

    </div>
   
</template>



<script>
export default {
    data() {
        return {
            xxx: 1
        };
    },
    props: ['btnvisible','loadtext','headertext'],
    methods: {
        setLoaded() {
            this.$store.dispatch('setAppLoaded');
        }
    },
    computed: {
        isTimeout(){
        // console.log("computed in VueContent image")
        return  this.$store.getters.getTimeoutFlag;
        },
        // loaded(){
        //     console.log("loaded")
        //     return this.$store.getters.getAppLoaded;
        // },
        appLanguage() {
        // console.log("computed appLanguage");
        // console.log(this.$store.getters.getAppLanguage);
        return this.$store.getters.getAppLanguage;
        }
    }
}
</script>



<style scoped>
.splash-container {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    background-color: rgb(0, 0, 0);
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    z-index: 1000;
}

.zwei-drittel {
  display: flex;
  /* background: blue; */
  flex: 2 1 auto;
  align-items: center;
  align-content: center;
  justify-content: space-around;
  width: 100%;
}

.ein-drittel {
    /* background: green; */
    flex: 1 1 auto;
}

.ein-drittel > p{
    font-family: FontLight;
    font-size: 32px;
    color: rgb(95, 95, 95);
}

.logo-svg-calc {
    width: 100%;
    background-image:url("../assets/svgs/bl.svg");
    background-size: cover;
    height: 0;
    padding: 0; 
    padding-bottom: calc(100% * 160 / 580);
}

.logo {

    width: 100%;
    background-image:url("../assets/svgs/bl.svg");
    background-size: cover;
    height: 100%;
    padding: 0; /* reset */
    padding-bottom: 100%;
    border: thin dotted darkgrey;
    justify-items: center; 
    
}

</style>