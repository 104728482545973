import { createStore } from 'vuex'


const store = createStore({

  state() {

    return { 

      debug: true,
      language: "de",
      loaded: false,
      page: 0,
      routecnt: 0,
      uebergangsvideo: false,
      showPart1: true,
      mainMenu: -1,
      impressum: false,
      hideBerge: false,
      kidsBubble: true,
      kidsGames: false,
      // kidsMenu: false,
      kids: false,
      kidsMenu: 0,
      kidsMenuGame: 0,
      gameNr: 1,
      content: {},
      contentUi: {},
      gamecomnr: 0,
      aufdecken: 0,
      fill2D: [    
        [1,1,1],
        [1,1,1],
        [1,1,1]
    ]

      // timeout: false,
      
    }
   
  },

  mutations: {

    setAppContentM(state, content) {
      // console.log("setAppContentM in store commit:" + content);
      state.content = content;
      // console.log(state.activeInfo);
    },

    setAppLoadedM(state) {
      // console.log("setAppLoadedM");
      state.loaded = true;
      // console.log(state.activeInfo);
    },

    // setRoomIdM(state, payload) {
    //   // console.log("setRoomIdM", payload);

    //   if (state.roomId != payload){
    //     state.maxRoomPage = state.contentWp[payload].children.length
    //     state.roomPageId = 0;
    //   }
    //   state.roomId = payload;
    //   // console.log(state.activeInfo);
    // },

    // checkRoomMaxPage(state){
    //   console.log("checkRoomMaxPage", state);
    // },

    setPageM(state, payload) {
      state.page = payload.page;
    },

    switchDebugM(state) {
      state.debug = !state.debug;
    },

    setDebugM(state, payload) {
      state.debug = payload.debug;
    },

    changePageM(state, page) {
      // console.log("changePageM in store commit:" + page);
      state.page = page;
      state.timeout = false;
      // console.log(state.activeInfo);
    },

    isTimeout() {
      // console.log("computed isTimeout");
      return this.$store.getters.getTimeoutFlag;
    },

    setAppUpM(state) {
      console.log("setAppUpM in store commit");
      if (state.card > -1) {
        state.page = 2;
        state.card = -1;
      } else {
        state.timeout = true;
      }
      // state.gui = true;
    },

  },

  actions: {

    setAppUp({ commit }) {
      // console.log("setAppTimeout in store");
      commit("setAppUpM"); // alles wieder auf anfang
    },

    resetAppTimeout({ commit }) {
      // console.log("resetAppTimeout in store");
      commit("resetAppTimeoutM"); // alles wieder auf anfang
    },

    setAppTimeout({ commit }) {
      // console.log("setAppTimeout in store");
      commit("setAppTimeoutM"); // alles wieder auf anfang
    },

    changePage({ commit }, page) {
      // console.log("changeMenu in store");
      commit("changePageM", page);
    },

    setAppLoaded({ commit }) {
      // console.log("setAppLoaded in store");
      commit("setAppLoadedM"); // alles wieder auf anfang
    },

    setAppContent({ commit }, content) {
      // console.log("setAppContent in store");
      commit("setAppContentM", content);
    },

    setPage({ commit }, payload) {
      // console.log("setPage", payload);
      commit("setPageM", payload); // in der mutation kann der wert geändert werden
    },

    setDebug({ commit }, payload) {
      commit("setDebugM", payload); // in der mutation kann der wert geändert werden
    },

    switchDebug({ commit }) {
      // console.log("switchDebug");
      commit("switchDebugM");

    },
  },
  modules: {},

  getters: {

    getAppLoaded(state) {
      // console.log("getAppLoaded from store");
      return state.loaded;
    },

    getAppTimeout(state) {
      // console.log("getAppTimeout from store");
      return state.timeout;
    },

    getDebugFlag(state) {
      // console.log("getDebugFlag from store", state.debug);
      return state.debug;
    },

    getAppText(state) {
      //console.log("getAppText from store");
      return state.apptext;
    },

    getPage(state) {
      // console.log("getDebugFlag from store");
      return state.page;
    },

  },
});

export default store;