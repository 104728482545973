export default {
  
  install: (app, optional) => {

    console.log(optional);

    const shuffle = (array) => {
      var currentIndex = array.length, temporaryValue, randomIndex;
    
      // While there remain elements to shuffle...
      while (0 !== currentIndex) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;
    
        // And swap it with the current element.
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
      }
    
      return array;
    }

    app.provide('shuffle', shuffle)

    app.config.globalProperties.$myRandom = (min, max)  => {
      return Math.random() * (max - min) + min;
    }
    // app.provide('myRandom', myRandom)
    
    app.config.globalProperties.$myRandomInt =(min, max)  => {
      return Math.floor(Math.random() * (max - min) + min);
    }
    // app.provide('myRandomInt', myRandomInt)

  }
}



// export function shuffle(array) {
//   var currentIndex = array.length, temporaryValue, randomIndex;

//   // While there remain elements to shuffle...
//   while (0 !== currentIndex) {
//     // Pick a remaining element...
//     randomIndex = Math.floor(Math.random() * currentIndex);
//     currentIndex -= 1;

//     // And swap it with the current element.
//     temporaryValue = array[currentIndex];
//     array[currentIndex] = array[randomIndex];
//     array[randomIndex] = temporaryValue;
//   }

//   return array;
// }


// export function myRandom(min, max) {
//   return Math.random() * (max - min) + min;
// }

// export function myRandomInt(min, max) {
//   return Math.floor(Math.random() * (max - min) + min);
// }