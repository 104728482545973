<template>

    <div class="bg"> 
        
        <svg viewBox="0 0 1840 1032" xmlns="http://www.w3.org/2000/svg">
            <!-- Example of a polygon with the default fill -->
            <!-- <polygon points="0,100 50,25 50,75 100,0" /> -->

            <!-- Example of the same polygon shape with stroke and no fill -->
            <!-- <template v-for="( polygon, index ) in polygonList" :key="index">
                
                <polygon v-if="index < polygonList.length-1" 
                        :points="polygonList[index][0][0]   + ',' + polygonList[index][0][1] + ' '   
                               + polygonList[index][1][0]   + ',' + polygonList[index][1][1] + ' '   
                               + polygonList[index+1][1][0] + ',' + polygonList[index+1][1][1] + ' '  
                               + polygonList[index+1][0][0] + ',' + polygonList[index+1][0][1]"
                        fill="#00ff00" stroke="black" stroke-width="5" @click="onRect(index)"/>
            
            </template>  -->


            <template v-for="( polygonList, index ) in polygon2DList" :key="index">
                
                <template  v-for="( poly, index2 ) in polygonList" :key="index2">
                    <polygon v-if="(index < polygon2DList.length-1) && (index2 < polygonList.length-1)"   
                        :points="polygon2DList[index][index2][0]   + ',' + polygon2DList[index][index2][1] + ' '   
                               + polygon2DList[index][index2+1][0]   + ',' + polygon2DList[index][index2+1][1] + ' '   
                               + polygon2DList[index+1][index2+1][0] + ',' + polygon2DList[index+1][index2+1][1] + ' '  
                               + polygon2DList[index+1][index2][0] + ',' + polygon2DList[index+1][index2][1]"
                        :fill="getFill(index, index2)" class="blend" stroke="black" :stroke-width="getStroke(index, index2)" @click="onRect(index, index2)" />  
                </template> 
            </template> 

            <!-- :style="getStyle(index, index2)" -->

        </svg>

    </div>

</template>



<script>
import { ref, onMounted } from "vue";  //  onMounted, onBeforeUnmount
import store from "../store/global-store";
import useEmitter from '@/composables/useEmitter.js'
import * as createjs from 'createjs-module';

// width: 1800, 
// height: 1028,

export default {

    name: "DrawSVGRect",

    setup() { // props

        // console.log("setup DrawSVGRectView");
        
        const polygon2DList = ref(Array);
        const emitter = useEmitter();
        // const fill2D = ref(Array);
        createjs.Sound.registerSound("/puppet/emopf.mp3", "emopf");
        // width: 1840px;
        // height: 1032px;

        onMounted( () => {
            // console.log( "DrawSVGRect");

            polygon2DList.value = [ 
                                    [ [2,0],   [300,2],   [1400,2],   [1838,2]   ],
                                    [ [2,300], [500,400], [1200,350], [1838,400] ],
                                    [ [2,600], [600,550], [1100,650], [1838,600] ],
                                    [ [2,1030], [500,1030], [1200,1030], [1838,1030] ]
                                ]
            if (store.state.gamecomnr == 0){
                store.state.fill2D = [    
                                [1,1,1],
                                [1,1,1],
                                [1,1,1]
                            ]
            }

            // store.state.fill2D =  [    
            //                     [1,1,1],
            //                     [1,1,1],
            //                     [1,1,1]
            //                 ]

            // fill2D.value = [    
            //                     [1,1,1],
            //                     [1,1,1],
            //                     [1,1,1]
            //                 ]
            
        })

        const onRect = (index, index2) => {
            
            if ( (store.state.aufdecken > 0) && (store.state.fill2D[index][index2] != 0 ) ){

                createjs.Sound.play("emopf");
                store.state.aufdecken--;

                // console.log("onRect", index, index2 );
                store.state.fill2D[index][index2] = 0;
                // fill2D.value[index][index2] = 0;
                if (store.state.aufdecken===0){
                    console.log ("alles aufgedeckt");
                    // store.state.gamecomnr++;
                    setTimeout(() => {
                        emitter.emit("GO_NEXT", ""  ); 
                    }, 800);
                    
                }

            } 
        }

        const getFill = (index, index2) => {
            // console.log("onRect", index, index2 );
            // if (fill2D.value[index][index2] ==  1){
            if (store.state.fill2D[index][index2] ==  1){
                return "#A3005F";
            } else {
                return "#00ff0000";
            }
        }

        const getStroke = (index, index2) => {
            // console.log("onRect", index, index2 );
            // if (fill2D.value[index][index2] ==  1){
            if (store.state.fill2D[index][index2] ==  1){
                return "5";
            } else {
                return "0";
            }
        }

        const getStyle = (index, index2) => {
            // console.log("onRect", index, index2 );
            // if (fill2D.value[index][index2] ==  1){
            if (store.state.fill2D.value[index][index2] ==  1){
                return {
                   opacity: '1.0',
                }
            } else {
                return {
                   opacity: '0.0',
                }
            }
        }


        return {
            polygon2DList,
            onRect,
            getFill,
            getStyle,
            getStroke
        }   
    }
}



</script>



<style scoped>
.blend {
    transition-duration: 0.5s;
    transition: fill 0.5s ease;
}

.bg {
    width: 1840px;
    height: 1032px;
    /* background-color: orange; */
    background-image: url('/images/maus-preis_2.jpg');
}
</style>