<template>
  
  <section>
      
      <div id="salzpfanne0">
          <img class="notouch" src="/salzpfanne/salzpfanne_1_1840x1035.jpg" alt="">
      </div>

      <transition name="fadepage" mode="out-in" appear>
      <div id="salzpfanne1" ref="salzpfanne1" >  <!-- v-if="store.state.showPart1==true" -->
            <img class="notouch" src="/salzpfanne/salzpfanne_1_1840x1035.jpg" alt="">

            <div id="piece-1" style="mix-blend-mode: unset; position: absolute;">
              <img src="/salzpfanne/salzpfanne_p1.png" alt="">
            </div>
            <div id="piece-2" style="mix-blend-mode: unset; position: absolute;">
              <img src="/salzpfanne/salzpfanne_p2.png" alt="">
            </div>
            <div id="piece-3" style="mix-blend-mode: unset; position: absolute;">
              <img src="/salzpfanne/salzpfanne_p3.png" alt="">
            </div>
            <div id="piece-4" style="mix-blend-mode: unset; position: absolute;" >
              <img src="/salzpfanne/salzpfanne_p4.png" alt="">
            </div>
            <div id="piece-5" style="mix-blend-mode: unset; position: absolute;">
              <img src="/salzpfanne/salzpfanne_p5.png" alt="">
            </div>
            <div id="piece-6" style="mix-blend-mode: unset; position: absolute;">
              <img src="/salzpfanne/salzpfanne_p6.png" alt="">
            </div>
      </div>
      </transition>
      
      <transition name="fadepage" mode="out-in" appear> <!-- @after-enter="onAfterEnter"-->
        <VideoTransition style="margin-top: 0px;" v-if="store.state.uebergangsvideo==true"  @emit-videoended="videoEnded($event)"></VideoTransition>
      </transition>


      <transition name="fadepageslow" mode="out-in" appear @after-enter="onAfterEnter">
        
          <div id="salzpfanne2" v-if="showPart2==true">
            <img class="notouch" src="/salzpfanne/salzpfanne_2_1840x1035.jpg" alt="">

            <div id="figure-1" style="mix-blend-mode: unset; position: absolute;opacity: 0;">
              <img src="/salzpfanne/salzpfanne_f1.png" alt="">
            </div>
            <div id="figure-2" style="mix-blend-mode: unset; position: absolute;opacity: 0;">
              <img src="/salzpfanne/salzpfanne_f2.png" alt="">
            </div>
            <div id="figure-3" style="mix-blend-mode: unset; position: absolute;opacity: 0;">
              <img src="/salzpfanne/salzpfanne_f3.png" alt="">
            </div>
            <div id="figure-4" style="mix-blend-mode: unset; position: absolute;opacity: 0;">
              <img src="/salzpfanne/salzpfanne_f4.png" alt="">
            </div>
            <div id="figure-5" style="mix-blend-mode: unset; position: absolute;opacity: 0;">
              <img src="/salzpfanne/salzpfanne_f5.png" alt="">
            </div>

          </div>  
        
      </transition>
      

      <!-- <div class="reflayer notouch">
        <div class="gameinfo notouch">
          <p v-if="showPart2==false"> {{ store.state.content.salzpiecesolved[store.state.language] }}</p>
          <p v-else> {{ store.state.content.salzfiguresolved[store.state.language] }}</p>
          <h1 v-if="showPart2==false"> {{ solvedPieces.length }} / {{ pieceMax }}</h1>
          <h1 v-else> {{ solvedFigures.length }} / {{ figureMax }}</h1>
        </div> 
        <p class="mouseinfo notouch"> {{ x }}, {{ y }} </p>
      </div> -->

      <GameProgress v-if="showPart2==false" :x="solvedPieces.length" :y="pieceMax" col="#A3005F"></GameProgress>
      <GameProgress v-else :x="solvedFigures.length" :y="figureMax" col="#A3005F"></GameProgress>
      
  </section>

</template>



<script>
import { gsap } from "gsap";
import { Draggable } from "gsap/Draggable";
import { InertiaPlugin } from "gsap/InertiaPlugin";
import * as createjs from 'createjs-module';
import GameProgress from "../components/GameProgress.vue"; 
import { ref, onMounted, onUnmounted, onBeforeUnmount} from "vue";  //  , inject,  getCurrentInstance
// import { useRoute } from 'vue-router';  // useRouter, 
import useEmitter from '@/composables/useEmitter.js'
import store from "../store/global-store";

// import { myRandom, myRandomInt } from "../helper/Helper.js";  //, useMouseMove
import { useMouseMove } from '../composables/mouse.js'

import VideoTransition from "../components/VideoTransition.vue";

export default {

  name: "SalzpfanneView",

  components : {
    VideoTransition,
    GameProgress,
  },

  props: {
    imageName : {
        type: String,
        required: false,
        default: "salzpfanne_1840x1035_1.jpg"
    }
  },

  setup() {

    // let frame;
    // const store  = useStore();
    // const route  = useRoute();
    const emitter = useEmitter();
    const { x, y } = useMouseMove();
    //const starttime = performance.now()
    const pieceMax = 6;
    const solvedPieces = ref([]);
    const figureMax = 5;
    const solvedFigures = ref ([])
    const showPart2 = ref(false);
    const showVideo = ref(false);
    const salzpfanne1 = ref('')
    const piecesStart = [[1600,500],[50,500],[1300,500],[700,500],[300,500],[1000,500]];
    const figuresStart = [[1600,-100],[50,-100],[700,-100],[400,-100],[1200,-100]];
    const pieces = [ [437.6666, -350.889], [389.2593,-212.7038],[688.1481, -238.6297],[951.8519,-163.4074],[1222.9631, -69.7408], [1274.074, -204.0742]];
    // const pieces = [[-1567,-254], [253,-351],[148,-72],[984,-212],[277,-425]];
    const figures = [[22,298], [458,214],[940,243],[1208,197],[1479,234]];
    // const figures = [[-262, 428], [-228,364],[-134, 376],[1074,308],[1320,357]];
    gsap.registerPlugin(InertiaPlugin) 
    gsap.registerPlugin(Draggable, InertiaPlugin);  // , InertiaPlugin
    Draggable.zIndex = 10;
    createjs.Sound.registerSound("/puppet/click.mp3", "click");

    const videoEnded = (val) => {
      console.log("videoEnded", val)
      showPart2.value = true;
      // emitter.emit("GO_NEXT", "" ); 
      emitter.emit("AFTER_SALZPFANNEVIDEO", "message from SalzpfanneView");
    };


    const onAfterEnter = () => {
      initDragAndDropFigures();
    }


    const initDragAndDropPieces = () => {
      // console.log("initDragAndDropPieces");
      // gsap.set("#piece-1", {xPercent: -50, yPercent: -50});

      for (var i=1; i<=pieceMax; i++){

          Draggable.create("#piece-" +i, {
            bounds: "#salzpfanne1",
            inertia: true,
            id: "#piece-" +i,
            refPoint: pieces[i-1],
            // snap: function (value) {
            //     //this function gets called by InertiaPlugin when the mouse/finger is released and it plots where rotation
            //     //should normally end and we can alter that value and return a new one instead. This gives us an easy way to
            //     //apply custom snapping behavior with any logic we want. In this case, we'll just make sure the end value snaps
            //     //to 90-degree increments but only when the "snap" checkbox is selected.
            //     return Math.round(value / 90) * 90;
            // },
            // liveSnap: function(value) { return Math.round(value / 50) * 50; },
            // liveSnap: {
            //   //snaps to the closest point in the array, but only when it's within 15px (new in GSAP 1.20.0 release):
            //   points: [
            //     { left: 177, top: 682 }//,
            //     // { x: 100, y: 0 },
            //     // { x: 200, y: 50 },
            //   ],
            //   radius: 60,
            // },
            onDragStart: function() {
              emitter.emit("CLOSE_GAMECOM", "Close message from SalzpfanneView" );
            },

            onDragEnd: function () {
              // console.log("drag ended", this);
              console.log("x", this.x, "y", this.y);
              // console.log("id", this.id);
              // console.log("style.left: ", parseInt(this.style.left, 10) , "style.top: ", parseInt(this.style.top, 10) ); // this.target.style.left;
              // let left = this.pointerX; // parseInt(this.style.left, 10); // this.target.style.left;
              // let top  = this.pointerY; // parseInt(this.style.top, 10);
              let left = this.x; 
              let top  = this.y; 
              
              // console.log("pointerX", left, "pointerY", top);
              
              // let refPoint = this.vars.pieces[i-1] // [-1527.5, -244];
              // console.log(this.vars.refPoint);

              if ( ( ( left < (this.vars.refPoint[0] +20) ) && ( left > (this.vars.refPoint[0] -20) )  ) && ( (top < (this.vars.refPoint[1] +20) ) ) && ( top > (this.vars.refPoint[1] -20) ) )  {
                console.log("treffer");
                createjs.Sound.play("click");
                solvedPieces.value.push(this.vars.id);
                if (solvedPieces.value.length == pieceMax) {
                  emitter.emit("GO_NEXT", "" );
                  // showVideo.value = true
                  // showPart2.value = true;
                }
                // gsap.to(this)
                // gsap.to("#piece-" +pos, { x: -1527.5, y: -244, duration: 1.0, ease: "power3"});
                gsap.to(this.vars.id, { x: this.vars.refPoint[0], y: this.vars.refPoint[1], duration: 1.0, ease: "power3"});
                let draggable = Draggable.get(this.vars.id);
                draggable.kill();
                
                // console.log(gsap.version);
                // gsap.quickTo("#piece-1", "x", {duration: 0.6, ease: "power3"}),
              }
            },
          });

      }
    }

    const setPlavVideo = () => {
      showVideo.value = true
    }

    const initDragAndDropFigures = () => {
      // console.log("initDragAndDropFigures");

      for (let index = 1; index <= 5; index++) {
         gsap.to("#figure-" + index,  { duration: 0.0, delay: 0, opacity: 0, x: figuresStart[index-1][0], y: figuresStart[index-1][1] });
         gsap.to("#figure-" + index,  { duration: 0.5, delay: 0.1, opacity: 1.0 });
      }


      for (var i=1; i<=5; i++){

          Draggable.create("#figure-" +i, {
            bounds: "#salzpfanne2",
            inertia: true,
            id: "#figure-" +i,
            refPoint: figures[i-1],
            
            onDragEnd: function () {
              console.log("drag ended", this);
              console.log("x", this.x, "y", this.y);
              let left = this.x; 
              let top  = this.y; 

              if ( ( ( left < (this.vars.refPoint[0] +30) ) && ( left > (this.vars.refPoint[0] -30) )  ) && ( (top < (this.vars.refPoint[1] +30) ) ) && ( top > (this.vars.refPoint[1] -30) ) ) {
                // console.log("treffer");
                createjs.Sound.play("click");
                solvedFigures.value.push(this.vars.id);
                // gsap.to(this)
                // gsap.to("#piece-" +pos, { x: -1527.5, y: -244, duration: 1.0, ease: "power3"});
                gsap.to(this.vars.id, { x: this.vars.refPoint[0], y: this.vars.refPoint[1], duration: 1.0, ease: "power3"});
                let draggable = Draggable.get(this.vars.id);
                draggable.kill();
                if (solvedFigures.value.length < figureMax){
                  emitter.emit("CLOSE_GAMECOM", "Close message from SalzpfanneView" );
                } else {
                  emitter.emit("AFTER_SALZPFANNE", "message from SalzpfanneView");
                }
                // Draggable.disable("#piece-1");
                // console.log(gsap.version);
              }
            },
          });
      
      }
    }

    
    onMounted( () => {

      // store.state.routecnt++;
      // console.log("routecounter", route.path, store.state.routecnt);

      for (let index = 1; index <= 6; index++) {
        gsap.to("#piece-" + index,  { duration: 0.0, delay: 0, x: piecesStart[index-1][0], y: piecesStart[index-1][1], scale: 1.0});
      }

      initDragAndDropPieces();
    })

    onBeforeUnmount(() => {

      for (var i=1; i<=6; i++){
        let draggable = Draggable.get("#piece-" + i);
        // console.log(draggable);
        if (draggable != undefined) {
          draggable.kill();
        }  
      }
      if (showPart2.value==true){
        for (i=1; i<=5; i++){
          let draggable = Draggable.get("#figure-" + i);
          // console.log(draggable);
          if (draggable != undefined) {
          draggable.kill();
        }  
        }
      }
      
    })

    onUnmounted(() => {
      // console.log("onUnmounted SalzpfanneView")
    });  
    

    return {
      store,
      x,
      y,
      solvedFigures,
      solvedPieces,
      pieceMax,
      figureMax,
      showPart2,
      showVideo,
      salzpfanne1,
      onAfterEnter,
      videoEnded,
      setPlavVideo,
    }

  },

  // watch: {
  //   // solved(newVal, oldVal) {
  //   //   console.log(newVal, oldVal);
  //   //   alert('Solved.')
  //   // }
  // },
  // computed: {
  //   // empty() {
  //   //   return this.tiles.findIndex(tile => tile === '9')
  //   // },
  //   // solved() {
  //   //   return _.isEqual(this.tiles, this.solution)
  //   // }
  // },
  // methods: {
  //   // move(i) {
  //   //   if (this.moves[i].includes(this.empty)) {
  //   //     let tileIndex  = i
  //   //     let tile       = this.tiles[i]
  //   //     let emptyIndex = this.empty
  //   //     this.$set(this.tiles, tileIndex, this.tiles[emptyIndex])
  //   //     this.$set(this.tiles, emptyIndex, tile)
  //   //   }
  //   // }
  // }
}
</script>



<style>

#salzpfanne0 {
  position: absolute;
  width: 1840px;
  height: 1035px;
  /* margin-top: 0px; */
  /* display: flex;
  flex-direction: column;
  justify-items: center;
  justify-content: center; */
  /* background-color: green; */
}


#salzpfanne1 {
  position: absolute;
  width: 1840px;
  height: 1035px;
  /* margin-top: 0px; */
  display: flex;
  flex-direction: column;
  justify-items: center;
  justify-content: center;
  /* background-color: green; */
}

#salzpfanne2 {
  position: absolute;
  /* top: 0px;
  left: 0px; */
  width: 1840px;
  height: 1035px;
  /* margin-top: 0px; */
  display: flex;
  flex-direction: column;
  justify-items: center;
  justify-content: center;
  z-index: 220;
  /* background-color: green; */
}

.notouch {
  /* position: absolute; */
  /* width: 1840px; */
  /* height: 1035px; */
  pointer-events: none;
}

.mouseinfo {
  /* position: absolute;
  left: 50px;
  bottom: 10px; */
  color: white;
  align-self: center;
  /* justify-self: end; */
  margin-top: auto;
  opacity: 0;
  /* justify-self: end; */
  /* align-items: baseline; */
}

.reflayer {
  position: absolute;
  /* background-color: aqua; */
  /* opacity: 0.5; */
  width: 1840px;
  height: 1035px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  z-index: 250;
  /* justify-content: center; */
}

.gameinfo {
  /* position: relatve; */
  /* top: 20px;
  left: 400px; */
  width: 220px;
  height: 140px;
  margin-top: 40px;

  background-color: white;
  border-radius: 30px;
  filter: drop-shadow(3px 3px 5px #353535);

  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  
  align-self: center;
  
}

.gameinfo > p {
  color:black;
  /* background-color: green; */
  text-align: center;
  padding: 0px;
  margin: 0px;
}

.gameinfo > h1 {
  color: #A3005F;
  /* background-color: blue; */
  text-align: center;
  font-size: 60px;
  line-height: 60px;
  margin-top: 10px;
}

</style>