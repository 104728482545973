<template>

     <div style="position:absolute; background-color: #f00;">
          
          <swiper class="swiper-container" ref="swiper" 
                              :pagination="{clickable: true}"
                              
                              :modules="modules"
                              :centeredSlides="false"
                              :centeredSlidesBounds="false"
                              :loop="false"
                              :simulate-touch="true"
                              :allow-touch-move="true"
                              :navigation="{
                                   'nextEl': '.pagination-rechts',
                                   'prevEl': '.pagination-links',
                                   }"
                              @swiper="onSwiper"
                              @slideChange="onSlideChange"
                              :breakpoints="{
                                   '992': {
                                        slidesPerView: 3,
                                        spaceBetween: 65,
                                   },
                                   '1200': {
                                        slidesPerView: 3,
                                        spaceBetween: 65,
                                   },
                              }"
                         >    
                              <swiper-slide v-for="item, index in store.state.content.kids.menus" :key="index" >
                              
                                   <div class="slide-preview" @click.passive="openKidsMenu(index)">
                                        <div class="preview-container">
                                             <img :src="item.image" alt="">
                                        </div>
                                        
                                        <div class="slide-text">
                                             <h4  v-html="item.sub[store.state.language]"></h4>
                                             <h2 v-html="item.titel[store.state.language]"></h2>
                                             <!-- <div class="deko__line-small"></div> -->
                                             <p  v-html="item.copy[store.state.language]"></p>
                                             <!-- <div class="slide-pfeil-bottom">
                                                  <img src="../assets/svgs/pfeil.svg" alt="">
                                             </div> -->
                                        </div>
                                        
                                   </div>  

                              </swiper-slide>
                              
          </swiper>

          <div class="pagination-links" > 
               <div class="page-button-links"  @click.passive="prevPage()" >  <!-- :style="showPageButton('left')" -->
                    <img src="../assets/svgs/rechtslinks.svg" alt="">
               </div>
          </div>  

          <div class="pagination-rechts" >                                                     
                   <div class="page-button-rechts" @click.passive="nextPage()"  >  <!-- :style="showPageButton('right')" -->
                    <img src="../assets/svgs/rechtslinks.svg" alt="">
               </div>                              
          </div> 

          <transition name="fadepage" mode="out-in" appear :key="store.state.kidsMenuGame" >
               <KidsMenuGames v-if="store.state.kidsMenuGame>0" ></KidsMenuGames>  
          </transition>
          
     </div>
                 
</template>



<script>
import { ref } from 'vue'; 

import { useRouter } from 'vue-router'  
import { useStore } from 'vuex'

import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import "swiper/swiper-bundle.css";
import { Pagination, Navigation } from "swiper";
// import { publicPath } from '../../vue.config';
import KidsMenuGames from '../components/KidsMenuGames.vue'
/* eslint-disable */

export default {

     name: "KidsMenuView",

     components: {
          Swiper,
          SwiperSlide,
          KidsMenuGames,
     },

     props: {
         
     },
     
     setup() {
          const store = useStore();
          const router = useRouter();

          let time = ref(0);
          let swiperIndex = ref(1);
          let swiper = null;

          const showPageButton = (mode) => {
               console.log(mode)
               // if (mode == "left"){    
               // } else if (mode == "right"){  
               // }
          }

          const nextPage = () => {
               // console.log("nextPage")
               // currBibel.value < maxBibel.value-1 ? currBibel.value ++ : currBibel.value = maxBibel.value;
               // checkBibelStellen();
          }

          const prevPage = () => { 
               // console.log("prevPage")
               // currBibel.value > 1 ? currBibel.value -- : currBibel.value = 1;
               // checkBibelStellen();
          }

          const onSwiper = (swipertemp) => {
               swiper = swipertemp
               // console.log(swiper);
          };
        
          const onSlideChange = (swiper) => {
               // console.log("activeIndex", swiper.activeIndex);
               swiperIndex.value = swiper.activeIndex
               if (swiperIndex.value == 0) {
               swiperIndex.value = 3
               } else if (swiperIndex.value ==4) {
               swiperIndex.value = 1
               }
               time.value = 0;
               // console.log('slide change');
          };

          const onLeft = () => {
               // console.log("left")
               swiper.slideNext();
          }

          const onRight = () => {
               //console.log("right")
               swiper.slideNext();
          }

          const openKidsMenu = (index) => {
               console.log("openKidsMenu", index)
               // store.state.kidsMenu = index;
               // store.state.page = 1;
               store.state.hideBerge = true;
               store.state.kidsBubble = false;
               store.state.kidsMenuGame = index +1

          }

          return {
               onSwiper,
               onSlideChange,
               onLeft,
               onRight,
               modules: [Pagination, Navigation],
               openKidsMenu,
               nextPage,
               prevPage,
               showPageButton,
               store,
          }
     },
}
</script>



<style lang="scss" scoped>
@import "../css/variables.scss";

.home-footer-deko {
     padding-top: 10rem;
}

.swiper-container {
     position: absolute;
     left: 100px;
     top: 205px;
     width: 1720px;
     height: 670px;
     // z-index: 0;
     // background-color: green;
}

.swiper-slide {
     // width: 100% !important;
     // width: 172px !important;
     // justify-content: space-between;
}


// das slide
.slide-preview h4 {
  font-family: FontRegular;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: normal;
  padding-top: 4px;
  padding-bottom: 0px;
  margin-bottom: 0px;
  color: $black;
}

.slide-preview h2 {
  margin-top: 0px;
  font-family: FontBold;
  font-size: 24px;
  line-height: 33px;
  font-weight: normal;
  margin-bottom: 6px;
  color: $black;
}

.slide-preview p {  // > nur direkte child, hier egal wie tief verschachtelt
  font-family: FontLight;
  font-size: 14px;
  line-height: 18px;
  color: $black;
  margin-top: 0rem;
  margin-bottom: 1.15rem;
  white-space: pre-line;
}

// .slide-preview  .deko__line {
//   background-color: $black;
//   width: 48px;
//   height: 3px;
//   border-radius: 6px;
// }

.slide-preview {
  display: flex;
  padding: 25px;
  flex-direction: column;
  background-color: $white;
  height: 488px;
  width: 530px;
  border-radius: 30px;
     // box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.418);
  filter: drop-shadow(3px 3px 5px #353535);
  transition: 1.0s ease all;
}

.slide-text {
  margin-left: 20px;
}

.slide-pfeil-bottom {
  // position: relative;
  // bottom: 0px;
  width: 21px;
  // align-self: left;
  // height: 50px;
  // margin-left: auto;
  // align-self: flex-end;
  // margin-top: auto;
  // margin-bottom: auto;
  // margin-bottom: 0px;
}

//   .raumkachel {
//     display: grid;
//     align-items: flex-end;
//     background-color: rosybrown;
//     // opacity: 0.2;
//     width: 592px;
//     height:1200px;
//   }

  .preview-container {
    background-color: #f4f805;
//     margin: 40px;
    width: 480px;
    height: 346px;
    border-radius: 20px;
  }

  .swiper-pagination{
     width: 100%;
     height: 15px;
     // background-color: orange;
}

:deep(.swiper-pagination .swiper-pagination-bullet ) {
    width: 10px;
    height: 10px;
     opacity: 1;
     border: #ffffff solid 2px;
     background-color: transparent;
}

:deep(.swiper-pagination .swiper-pagination-bullet-active) {
     background-color: #ffffff
}

.pagination-links {
     position: absolute;
     left: 25px;
     top: 421px;
     height: 25px;
     width: 25px;
    transition-duration: 1.0s;
     // background-color: yellow;
     // width:
}

.pagination-rechts {
     // background-color: #f80000;
     position: absolute;
     left: 1871px;
     top: 482px;
     width: 22px;
     transform-origin: center;
     transform: rotate(180deg);
     transition-duration: 1.0s;
     height: 25px;
     width: 25px;
     // background-color: yellow;
     // width:
}

.swiper-button-disabled {
     opacity: 0.0;
}
</style>



<style>
:root {
    --swiper-navigation-size: 42px;
    --swiper-navigation-sides-offset: 0px;
    --swiper-theme-color: #fa0000;
    --swiper-navigation-color: var(--swiper-theme-color);
}
</style>
