<template>

     <div style="position:absolute; background-color: #f00;">
          
          <swiper class="swiper-container" ref="swiper" 
               :pagination="{clickable: true}"
               
               :modules="modules"
               :centeredSlides="false"
               :centeredSlidesBounds="false"
               :loop="false"
               :simulate-touch="true"
               :allow-touch-move="true"
               :navigation="{
                    'nextEl': '.pagination-rechts',
                    'prevEl': '.pagination-links',
                    }"
               @swiper="onSwiper"
               @slideChange="onSlideChange"
               :breakpoints="{
                    '496': {
                         slidesPerView: 3,
                         spaceBetween: 75,
                    },
                    '600': {
                         slidesPerView: 3,
                         spaceBetween: 75,
                    },
               }"
          >    
               <swiper-slide v-for="item, index in store.state.content.menus" :key="index" >
               
                    <div class="slide-preview" @click.passive="openMainMenu(index)">
                         <div class="preview-container">
                              <img :src="item.image" alt="">
                         </div>
                         
                         <div class="slide-text">
                              <h4 v-html="item.sub[store.state.language]"></h4>
                              <h2 v-html="item.titel[store.state.language]"></h2>
                              <!-- <div class="deko__line-small"></div> -->
                              <p  v-html="item.copy[store.state.language]"></p>
                              <!-- <div class="slide-pfeil-bottom">
                                   <img src="../assets/svgs/pfeil.svg" alt="">
                              </div> -->
                         </div>
                         
                    </div>  

               </swiper-slide>
                              
          </swiper>

          <div class="pagination-links" > 
               <div class="page-button-links"  @click.passive="prevPage()" >  <!-- :style="showPageButton('left')" -->
                    <img src="../assets/svgs/rechtslinks.svg" alt="">
               </div>
          </div>  

          <div class="pagination-rechts" >                                                     
                   <div class="page-button-rechts" @click.passive="nextPage()"  >  <!-- :style="showPageButton('right')" -->
                    <img src="../assets/svgs/rechtslinks.svg" alt="">
               </div>                              
          </div> 

          <transition name="fadepage" mode="out-in" appear :key="store.state.kidsGames">
               <KidsGames v-if="store.state.kidsGames==true" ></KidsGames>  
          </transition>
          
     </div>
                 
</template>



<script>
import { ref } from 'vue'; 

import { useRouter } from 'vue-router'  
import { useStore } from 'vuex'

import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import "swiper/swiper-bundle.css";
import { Pagination, Navigation } from "swiper";  

import KidsGames from '../components/KidsGames.vue'
// import { publicPath } from '../../vue.config';

/* eslint-disable */

export default {

     name: "HomeView",

     components: {
          Swiper,
          SwiperSlide,
          KidsGames,
     },

     props: {
         
     },
     
     setup() {
          const store = useStore();
          const router = useRouter();

          let time = ref(0);
          let swiperIndex = ref(1);
          let swiper = null;

          const showPageButton = (mode) => {
               console.log(mode)
               // if (mode == "left"){    
               // } else if (mode == "right"){  
               // }
          }

          const nextPage = () => {
               // console.log("nextPage")
               // currBibel.value < maxBibel.value-1 ? currBibel.value ++ : currBibel.value = maxBibel.value;
               // checkBibelStellen();
          }

          const prevPage = () => { 
               // console.log("prevPage")
               // currBibel.value > 1 ? currBibel.value -- : currBibel.value = 1;
               // checkBibelStellen();
          }

          const onSwiper = (swipertemp) => {
               swiper = swipertemp
               // console.log(swiper);
          };
        
          const onSlideChange = (swiper) => {
               // console.log("activeIndex", swiper.activeIndex);
               swiperIndex.value = swiper.activeIndex
               if (swiperIndex.value == 0) {
               swiperIndex.value = 3
               } else if (swiperIndex.value ==4) {
               swiperIndex.value = 1
               }
               time.value = 0;
               // console.log('slide change');
          };

          const onLeft = () => {
               // console.log("left")
               swiper.slideNext();
          }

          const onRight = () => {
               //console.log("right")
               swiper.slideNext();
          }

          const openMainMenu = (index) => {
               store.state.mainMenu = index;
               store.state.page = 1;
               store.state.hideBerge = true;
               store.state.kidsBubble = false;
               if (store.state.content.menus[index].intro.route=="intropage" ) {
                    router.push({ name: 'intropage', props: true} )  // params: { "bookID": "2"}, 
               // } else if (store.state.content.menus[index].intro.route=="videopage" ) {
               //      router.push({ name: 'videopage', props: true} )  // params: { "bookID": "2"}, 
               // } else if (store.state.content.menus[index].intro.route=="videopageplyr" ) {
               //      router.push({ name: 'videopageplyr', props: true} )  // params: { "bookID": "2"}, 
               } else  {
                    router.push({ name: store.state.content.menus[index].intro.route, props: true} )  // params: { "bookID": "2"}, 
               }
               
               // if ( index > 0 ){ // es handelt sich um eins der nicht bibel bücher
               //      loadBookTexte(index);
               // }
               // console.log("contentRoom", store.state.roomId );
               // router.push({ name: "rundgangPage", params: { roomId: store.state.roomId, language: store.state.language}, props: true})
          }

        
          // const loadBookTexte = (index) => {
      
          //      if (process.env.NODE_ENV === "production"){
          //           var base_url = window.location.origin + publicPath   // "https://www.bluelemon.de/demo/AL/VB_v1/" 
          //      } else {
          //           base_url =  window.location.origin + "/" + publicPath //   
          //      }
          //      // console.log("content base_url", base_url + 'content.json')
          //      let jsonname = store.state.content.menus[index].json;
          //      fetch(base_url + jsonname, { method: 'get', headers: { 'content-type': 'application/json' } } )
          //      .then(res => {
          //      if (!res.ok) {
          //           console.log("loaded NOOK")
          //           // create error instance with HTTP status text
          //           const error = new Error(res.statusText);
          //           error.json = res.json();
          //           throw error;
          //      }
          //      return res.json();
          //      })
          //      .then(json => {
          //           // console.log("json", json)
          //           store.state.buchTexte = json;
          //      })
               
          // }


          return {
               onSwiper,
               onSlideChange,
               onLeft,
               onRight,
               modules: [Pagination, Navigation],
               openMainMenu,
               nextPage,
               prevPage,
               showPageButton,
               // openKidsMenu,
               store,
          }
     },
}
</script>



<style lang="scss" scoped>
@import "../css/variables.scss";

.home-footer-deko {
     padding-top: 10rem;
}

.swiper-container {
     position: absolute;
     left: 100px;
     top: 205px;
     width: 1720px;
     height: 540px;
     // z-index: 0;
     // background-color: green;
}

.swiper-slide {
     // width: 100% !important;
     // width: 172px !important;
     // justify-content: space-between;
}


// das slide
.slide-preview h4 {
  font-family: FontRegular;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  font-weight: normal;
  padding-top: 0px;
  padding-bottom: 0px;
  margin-bottom: 0px;
  color: $black;
}

.slide-preview h2 {
  margin-top: 0px;
  font-family: FontBold;
  font-size: 24px;
  line-height: 33px;
  font-weight: normal;
  margin-bottom: 6px;
  color: $black;
}

.slide-preview p {  // > nur direkte child, hier egal wie tief verschachtelt
  font-family: FontLight;
  font-size: 14px;
  line-height: 18px;
  color: $black;
  margin-top: 0rem;
  margin-bottom: 1.15rem;
  white-space: pre-line;
}

// .slide-preview  .deko__line {
//   background-color: $black;
//   width: 48px;
//   height: 3px;
//   border-radius: 6px;
// }

.slide-preview {
  display: flex;
  padding: 25px;
  flex-direction: column;
  background-color: $white;
  height: 489px;
  width: 530px;
  border-radius: 30px;
     // box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.418);
  filter: drop-shadow(3px 3px 5px #353535);
  transition: 1.0s ease all;
}

.slide-text {
     margin-left: 20px;
}

.slide-pfeil-bottom {
  // position: relative;
  // bottom: 0px;
  width: 42px;
  // align-self: left;
  // height: 50px;
  // margin-left: auto;
  // align-self: flex-end;
  // margin-top: auto;
  // margin-bottom: auto;
  // margin-bottom: 0px;
}

  .preview-container {
    background-color: #8f8f8f;
    // margin: 40px;
    width: 480px;
    height: 346px;
    border-radius: 20px;
  }

  .swiper-pagination{
     width: 100%;
     height: 15px;
     // background-color: orange;
}

:deep(.swiper-pagination .swiper-pagination-bullet ) {
     width: 10px;
     height: 10px;
     opacity: 1;
     border: #ffffff solid 2px;
     background-color: transparent;
}

:deep(.swiper-pagination .swiper-pagination-bullet-active) {
     background-color: #ffffff
}

.pagination-links {
     position: absolute;
     left: 28px;
     top: 402px;
     // height: 50px;
     // width: 50px;
     // background-color: yellow;
     // width:
}

.pagination-rechts {
     // background-color: #f80000;
     position: absolute;
     left: 1840px;
     top: 400px;
     transform-origin: center;
     transform: rotate(180deg);
     // height: 50px;
     // width: 50px;
     // background-color: yellow;
     // width:
}

.swiper-button-disabled{
     opacity: 0.4;
}

.page-button-links {
  -webkit-transform: scaleX(0.6) scaleY(0.6);
  transform: scaleX(0.6) scaleY(0.6);
}
.page-button-rechts {
  -webkit-transform: scaleX(0.6) scaleY(0.6);
  transform: scaleX(0.6) scaleY(0.6);
}

</style>



<style>
:root {
    --swiper-navigation-size: 42px;
    --swiper-navigation-sides-offset: 0px;
    --swiper-theme-color: #fa0000;
    --swiper-navigation-color: var(--swiper-theme-color);
}
</style>
