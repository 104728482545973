import { createRouter , createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import KidsView from '../views/KidsMenuView.vue'
// import EmptyView from '../views/EmptyView.vue'
import DrawSVGRectView from '../views/DrawSVGRectView.vue'
import PuzzleView from '../views/PuzzleView.vue'
import StollenView from '../views/StollenView_v2.vue'
import SalzpfanneView from '../views/SalzpfanneView.vue'

// import EmptyView2 from '../views/EmptyView.vue'
import PuzzleView2 from '../views/PuzzleView.vue'
import StollenView2 from '../views/StollenView_v2.vue'
import SalzpfanneView2 from '../views/SalzpfanneView.vue'

import VideoViewPlyr from '../views/VideoViewPlyr-fs-transparent.vue'
import ImpressumView from '../views/ImpressumView.vue'
// import { useStore } from 'vuex'
// Vue.use(VueRouter);

function checkfalseUrl(to) {
  console.log("checkfalseUrl", to)
  // if (to.hash) return { path: to.path, query: to.query, hash: '' }
}

var routes = [

  {
    path: "/homepage",
    name: "homepage",
    component: HomeView,
    children: [
                {
                  path: 'empty',
                  name: "empty",
                  component: DrawSVGRectView
                },
                {
                  path: 'puzzle',
                  name: "puzzle",
                  component: PuzzleView
                },
                {
                  path: 'salzpfanne',
                  name: "salzpfanne",
                  component: SalzpfanneView
                },
                {
                  path: 'stollen',
                  name: "stollen",
                  component: StollenView
                }
    ]
  },

  {
    path: "/",
    name: "",
    component: HomeView //,
    // children: [
    //             {
    //               path: 'empty',
    //               name: "empty",
    //               component: DrawSVGRectView
    //             }
    //       ]
  },

  {
    path: "/videoPagePlyr",
    name: "videopageplyr",
    component: VideoViewPlyr
  },
  
  {
    path: "/impressumPage",
    name: "impressumpage",
    component: ImpressumView
  },
  
  {
    path: "/kidsmenuPage",
    name: "kidsmenupage",
    component: KidsView, 
    children: [
        // {
        //   path: 'empty2',
        //   name: "empty2",
        //   component: EmptyView
        // },
          {
            path: 'puzzle2',
            name: "puzzle2",
            component: PuzzleView2
          },
          {
            path: 'salzpfanne2',
            name: "salzpfanne2",
            component: SalzpfanneView2
          },
          {
            path: 'stollen2',
            name: "stollen2",
            component: StollenView2
          }
    ]
  },

  {
    path: "/:catchAll(.*)",
    name: "",
    component: HomeView,
    beforeEnter: checkfalseUrl
    // (to, from) => {
    //   // reject the navigation
    //   return false
    // },
  },
];

// mode: 'hash',
// base: process.env.BASE_URL,

const router = createRouter({
   history: createWebHistory(),
  // history: createWebHistory(process.env.BASE_URL),
  // history: createWebHistory(process.env.BASE_URL),
  routes,
  // https://stackoverflow.com/questions/71196755/linking-router-link-and-scrollbehavior-not-working-vue-3-and-ionic-6
  // eslint-disable-next-line no-unused-vars
  
  // scrollBehavior: (to, from, savedPosition) => {
  //   console.log("scrollBehavior", window.location.href)
  //   // window.scrollTo(0, 0);
  //   if (to.hash) {
  //     console.log("scrollBehavior", window.location.href)
  //     const el = window.location.href.split("#")[1];
  //     if (el.length) {
  //       document.getElementById(el).scrollIntoView({ behavior: "smooth" });
  //     }
  //   } else if (savedPosition) {
  //     return savedPosition;
  //   } else {
  //     document.getElementById("app").scrollIntoView({ behavior: "smooth" });
  //   }
  //   return { x: 0, y: 0 }
  // },

  // eslint-disable-next-line no-unused-vars
  // beforeEach :(to, from) => {
    // if(to.name === "Home" && !to.query.hasOwnProperty("pageId")){
    //     to.query.pageId = "7"
    // }
    
    // console.log("to", to)
    // if(!to.query.hasOwnProperty("pageId")){
    //   to.query.pageId = "7"
    // }
  
  // }
})


// let store = useStore()

// nicht hier initialisisert, der store ist noch nicht verfügbar
// // eslint-disable-next-line no-unused-vars
// router.beforeEach((to, from) => {
  
//   console.log("to", to.params )
//   // Object.prototype.hasOwnProperty.call(object, "objectProperty");
//   if(!Object.prototype.hasOwnProperty.call(to.params, "pageId") ) {
//     to.params.pageId = 1;
//   } else {
//     console.log("EXXXTERN PageID", to.params.pageId)
//     // console.log(store)
//     store.state.roomPageId = to.params.pageId;
//   }
//   console.log("to", to)

// })


export default router