<template>

        <div class="floater">
            <!-- <div style=""> -->
                <div style="margin-left: 0px; margin-bottom: 0px;  width: 500px; transition: all 1.0s;" :style="getProgressStyle()" >

                </div>
            <!-- </div> -->
           
            <!-- <div class="btn" @click="goNext()" v-if="store.state.content.kids.gamecom[store.state.gamecomnr].button !=''">
                <img src="../assets/svgs/pfeil.svg" alt="">
                <p>{{ store.state.content.kids.gamecom[store.state.gamecomnr].button }}</p>
                
            </div> -->
        </div>

</template>



<script>

// import useEmitter from '@/composables/useEmitter.js'
// import { useStore } from 'vuex'


export default {
    
    name: "GameProgress",

    props: {
      x: Number,
      y: Number,
      col: String
    },

    // components: {
    // },  

    setup(props) { // props
        // const store  = useStore();
        // const emitter = useEmitter();
        // // const startTimer = performance.now();
        // const  barColor = ['#bdba2b','#00ff00', '#00ffff', '#00ffff']

        // console.log("GameProgress", props.col);
       
        const getProgressStyle = () => {
            // console.log("GameCom goNext", startTimer)
            return {
                width:  (1840/props.y*props.x) + 'px',
                backgroundColor: props.col,
            }
            // emitter.emit("GO_NEXT", ""  );  // ",message " + startTimer
        }

        return {
            getProgressStyle,
            // goNext,
            // store
        }
    }

}
</script>



<style scoped>
.floater {
    position: absolute;
    left: 0px;
    top: 0px;
    /* background-color: rgb(221, 245, 8); */
    
    z-index: 10000;

    display: flex;
    /* flex-direction: column; */
    flex: 1;
    
    width: 1840px;
    height: 16px;
    
    border-radius: 0px;
    margin-left: 40px;
    margin-top: 8px;
}

/* h3 {
    font-family: FontBold;
    color: black;
    font-size: 44px;
} */

/* .salzmaus {
    position: absolute;
    top: -15px;
    left: -110px;
    transform: scaleX(-0.75 ) scaleY(0.75);
    align-items: left;
} */

/* .btn {
    position: absolute;
    display: flex;
    flex-direction: row-reverse;
    height: 40px;
    bottom: 20px;
    right: 30px;
} 
*/

/* .floater p {
    line-height: 40px;
    width: 480px;
    margin: 0px;
    padding: 0px;
} */

/* .btn > p {
    font-family: FontSerifItalic;
    width: auto;
    margin: 0px;
    padding: 0px;
    font-size: 32px;
    line-height: 44px;
} */

/* .btn > img {
    padding-left: 20px;
} */

</style>