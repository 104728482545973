<template>
    <div class="bubble">

      <div class="circle"></div>

      <div class="textarea">

        <transition-group name="mask" mode="out-in" appear>

            <div class="textarea-child" v-if="time>0&&time<10">
                <h2 v-html="store.state.contentUi.bubble[store.state.language]"></h2>
            </div>

            <div class="textarea-child" v-if="time>11&&time<16">
                <div class="img-container">
                  <img src="/images/puzzleteil-klein.png" alt="">
                </div>
            </div>


            <div class="textarea-child" v-if="time>17&&time<26">
                <h2 v-html="store.state.contentUi.bubble2[store.state.language]"></h2>
            </div>

            <div class="textarea-child" v-if="time>27&&time<33">
              <div class="img-container">
                  <img src="/images/maus-steht-200px.png" alt="">
                </div>
            </div>

            <div class="textarea-child" v-if="time>34&&time<42">
                <h2 v-html="store.state.contentUi.bubble3[store.state.language]"></h2>
            </div>

        </transition-group>

      </div>

    </div>
</template>



<script>
import { ref, onMounted, onBeforeUnmount } from 'vue'; // onMounted, computed, inject
import { useStore } from 'vuex'
import { publicPath } from '../../vue.config';


export default {

  name: "KidsBubble",

  setup() {
      let time = ref(0)
      let iid = 0;
      var base_url = window.location.origin + "/" + publicPath
      const store = useStore();

      onMounted(() => {
          iid = setInterval(() => {
              // console.log("interval")
              time.value++;
              if (time.value > 43){
                  time.value = 0;
              }
          }, 1000);

      })

      onBeforeUnmount(() => {
          clearInterval(iid);
      })

      return {
          time,
          base_url,
          store
      }
  },
}
</script>




<style lang="scss" scoped>
@import "../css/variables.scss";

.img-container {
    width: 250px;
    height: 250px;
    // background-color: #0a0a23;
    display: flex;
    justify-content: center;
    align-items: center;
}

.img-container img {
    width: 150px;
    height: 150px;
}


.bubble {
    position: absolute;
    left: 1040px;
    bottom: 320px;
}


.circle {
  position: absolute;
  top: 0px;
  left: 0px;
  margin: 20px;
  height: 250px;
  width: 250px;
  background-color: $purple;
  border-radius: 50%;
}

.circle:before {
  position: absolute;
  content: '';
  height: calc(100% + 11px); 
  width: calc(100% + 11px); 
  border: 6px dotted $purple;
  top: -11px;
  left: -11px;
  border-radius: inherit;
  // animation: spin 20s linear infinite;
  animation: fadeInOutAnimation 2s ease-in-out infinite;
}

@keyframes spin { 
  100% { 
    transform: rotateZ(-360deg); 
  }
}

.textarea {
  // position: relative;
  position: absolute;
  // background-color: orange;
  width: 250px;
  height: 250px;
  margin: 20px;

  display: grid;
  vertical-align: center;
  justify-self: center;
}

.textarea-child {
  vertical-align: center;
  display: grid;
  // justify-self: center;
  align-items: center;
  //  justify-content:center;
}

.textarea h2 {
  font-family: FontSerifItalic;
  font-size: 35px;
  line-height: 48px;
  text-align: center;
  color: $whitetypo;
}

.mask-enter-active {
  -webkit-mask-image: linear-gradient(
    90deg,
    #fff 0%,
    #fff 30%,
    #0000 70%,
    #0000 100%
  );

  mask-image: linear-gradient(
    90deg,
    #fff 0%,
    #fff 30%,
    #0000 70%,
    #0000 100%
  );

  -webkit-mask-size: 300% 100%;
  // -webkit-mask-size: 300% 100%;
  mask-size: 300% 100%;
  // mask-size: 300% 100%;
  animation: clip-fade-start 2.5s;
  transition: all 0.3s ease-in;
}

.mask-enter-from,
.mask-leave-to {
  animation: clip-fade-start 2.5s;
}

.mask-leave-active {
  -webkit-mask-image: linear-gradient(
    90deg,
    #fff 0%,
    #fff 30%,
    #0000 70%,
    #0000 100%
  );

  mask-image: linear-gradient(90deg, #fff 0%, #fff 30%, #000 70%, #000 100%);
  -webkit-mask-size: 400% 100%;
  mask-size: 400% 100%;
  animation: clip-fade-left 1s;
  transition: all 0.8s ease-out;
  opacity: 0;
  transform: translateX(20px);
}

@keyframes clip-fade-start {
   0% {
    -webkit-mask-position: right;
    mask-position: right;
  }

  100% {
    -webkit-mask-position: left;
    mask-position: left;
  }
}

@keyframes clip-fade-end {
  100% {
    -webkit-mask-position: left;
    mask-position: left;
  }
}

@keyframes clip-fade-left {
  100% {
    -webkit-mask-position: right;
    mask-position: right;
  }
}

@keyframes clip-fade-right {
  100% {
    -webkit-mask-position: right;
    mask-position: right;
  }
}

@keyframes fadeInOutAnimation {
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}
@-o-keyframes fadeInOutAnimation{
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}
@-moz-keyframes fadeInOutAnimation{
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}
@-webkit-keyframes fadeInOutAnimation{
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}
.animate-fadeinout {
   -webkit-animation: fadeInOutAnimation 1s infinite;
   -moz-animation: fadeInOutAnimation 1s infinite;
   -o-animation: fadeInOutAnimation 1s infinite;
    animation: fadeInOutAnimation 1s infinite;
}

</style>