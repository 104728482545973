<template>
    
    <div style="position:absolute; z-index: 10;">

        <!-- @ended="onEndedAction()"  :data-poster="propPoster" -->
        <!-- <track default kind="captions" label="English captions" src="/videos/projektor_v3.vtt" srclang="en"  /> -->

        <!-- <transition name="fadepageslow" mode="out-in" appear :key="store.state.language" @after-enter="onAfterEnter">  :style="getGameContainerStyle()"-->
        <div class="game-container">
          
            <div class="game-area" :style="getGameAreaStyle()"> 
              
              <router-view  v-slot="{ Component }" :key="route.path + store.state.routecnt">  
                  <transition name="fadepageslow" appear mode="out-in" >
                      <component :is="Component" class="animated" :key="route.path + store.state.routecnt" />
                  </transition>
              </router-view>
              
            </div>

            <div class="site-control"  @click="closeGame()">
                <img class="svg-close" src="../assets/svgs/close.svg" alt="" >
                <!-- <p>Schliessen</p> -->
            </div>   

            <!-- <div class="site-left">
              <h1>{{store.state.gamecomnr}}</h1>
            </div>    -->

        </div> 

        <transition name="fadepageslow" mode="out-in"> <!-- appear -->
          <!-- <GameCom v-if="showCom==true" :key="route.path + getPerfomance()"></GameCom> -->
          <GameCom v-if="showCom==true" :key="route.path + store.state.routecnt"></GameCom>
        </transition>
        
    </div>
    
</template>



<script>

/*****************************
 * 
 * gameNr 0 intro salzmaus
 * gameNr 1 salzmaus   => auflösung layer dalli klick
 * gameNr 2 intro puzzle
 * gameNr 3 puzzle     => auflösung layer dalli klick
 * gameNr 4 intro suchbild 
 * gameNr 5 suchbild   => auflösung dalli klick
 * gameNr 6 tschüss und aufgedecktes bild 
 * 
****************************/

// https://glb-packer.glitch.me/  packt den kram zusammen
// plyr: https://github.com/sampotts/plyr
import { onMounted, onBeforeUnmount, ref } from 'vue';  // reactive, watch, ref, 
import { useRouter, useRoute } from 'vue-router'
// import { useRouter } from 'vue-router'  
import { useStore } from 'vuex'
// import { publicPath } from '../../vue.config';
import GameCom from './GameCom.vue';
import useEmitter from '@/composables/useEmitter.js';


export default {
  
  inheritAttrs: false,

  name: "KidsGames",

  components: {
    GameCom,
  },

  props: {
    // propPoster:  {
    //   default: "/videos/PaterNoster_v08_HD.jpg"
    // },
    // propVideo: {
    //   default: "/videos/PaterNoster_v08_HD.mp4"
    // } 
  },

  setup() {
    const store  = useStore();
    const router = useRouter();
    const route  = useRoute();

    const showCom = ref(false);
    const emitter = useEmitter();
    // console.log ("kidsgameVue setup gamecomnr", store.state.gamecomnr);
    store.state.gamecomnr = 0;
    console.log ("kidsgameVue setup gamecomnr", store.state.gamecomnr);
    store.state.routecnt++;
    store.state.showPart1 = true;
    store.state.uebergangsvideo = false;



    onMounted(() => {

      // router.push( {path:"/homepage/empty"} );
      // console.log("onMounted");
      router.push( {path:"/homepage/empty"} );

      setTimeout(() => {
        showCom.value = true;
      }, 700);


      emitter.on("GO_NEXT", message => {
        console.log("GO_NEXT KidsGames.vue", "gamecomnr", store.state.gamecomnr), message;

        if (store.state.gamecomnr == 0){
          router.push( {path:"/homepage/puzzle"} );
          console.log("puzzle push ausgeführt");
          // router.push( {path:"/homepage/salzpfanne"} );
          setTimeout(() => {
            showCom.value = false;
          }, 300);
          setTimeout(() => {
            store.state.gamecomnr++;
          }, 1100);
        
        } else if (store.state.gamecomnr == 1) {
          router.push( {path:"/homepage/empty"} );
          setTimeout(() => {
            showCom.value = false;
          }, 300);
          setTimeout(() => {
            store.state.gamecomnr++;
            showCom.value = true;
          }, 1100);


        } else if (store.state.gamecomnr == 2) {
            router.push( {path:"/homepage/stollen"} );
            setTimeout(() => {
              showCom.value = false;
            }, 300);
            setTimeout(() => {
              store.state.gamecomnr++;
              showCom.value = true;
            }, 1100);
          
          } else if (store.state.gamecomnr == 4) {
            router.push( {path:"/homepage/empty"} );
            setTimeout(() => {
              showCom.value = false;
            }, 300);
            setTimeout(() => {
              store.state.gamecomnr++;
              showCom.value = true;
            }, 1100);

          } else if (store.state.gamecomnr == 5) {
            router.push( {path:"/homepage/salzpfanne"} );
            setTimeout(() => {
              showCom.value = false;
            }, 300);
            setTimeout(() => {
              store.state.gamecomnr++;
              showCom.value = true;
            }, 1100);

          } else if (store.state.gamecomnr == 6) {
            store.state.gamecomnr = 7;
            setTimeout(() => {
              showCom.value = true;
            }, 300);
          } else if (store.state.gamecomnr == 7) {
            showCom.value = false;
            // store.state.gamecomnr = 7;
            store.state.showPart1 = false;
            setTimeout(() => {
              store.state.uebergangsvideo = true;
              store.state.gamecomnr = 8;
            }, 500);

          } else if (store.state.gamecomnr == 8) {
            showCom.value = true;
            // store.state.gamecomnr = 7;
            setTimeout(() => {
              store.state.gamecomnr = 9;
            }, 500);

          } else if (store.state.gamecomnr == 9) {
            console.log("!!!!!!!!!!!!!!!!!!!! Go Next 10")
            setTimeout(() => {
              router.push( {path:"/homepage/empty"} );
              showCom.value = false;
            }, 300);
            setTimeout(() => {
              store.state.gamecomnr++;
              showCom.value = true;
            }, 1100);
          } else if (store.state.gamecomnr == 10) {
            setTimeout(() => {
              showCom.value = false;
            }, 300);
            setTimeout(() => {
              store.state.gamecomnr++;
              showCom.value = true;
            }, 1100);
            // showKidsMenu.value = true

          } else if (store.state.gamecomnr >= 11) {
            closeGame();
        }

      })
        

      emitter.on("AFTER_PUZZLE", message => {
        console.log("AFTER_PUZZLE", message);
        store.state.gamecomnr = 1;
        setTimeout(() => {
            showCom.value = true;
        }, 500);
      })

      emitter.on("GO_SALZPFANNE", message => {
        console.log("GO_SALZPFANNE", message);
        router.push( {path:"/homepage/stollen"} );
        setTimeout(() => {
          showCom.value = false;
        }, 300);
        setTimeout(() => {
        store.state.gamecomnr++;
          showCom.value = true;
        }, 1100);
      })
      
      emitter.on("AFTER_STOLLEN", message => {
        console.log("AFTER_STOLLEN", message);
        store.state.gamecomnr = 4;

        setTimeout(() => {
          showCom.value = true;
        }, 600);

        // setTimeout(() => {
        //   router.push( {path:"/homepage/empty"} );
        // }, 600);
        // setTimeout(() => {
        //    showCom.value = true;
        // }, 1200);


      })


      emitter.on("AFTER_SALZPFANNE", message => {
        console.log("AFTER_SALZPFANNE", message);
        store.state.aufdecken = 3;
        store.state.gamecomnr = 9;
          setTimeout(() => {
            showCom.value = true;
          }, 600);

        // setTimeout(() => {
        //     showCom.value = true;
        // }, 500);
      })

      emitter.on("AFTER_SALZPFANNEVIDEO", message => {
        console.log("AFTER_SALZPFANNEVIDEO", message);
        // store.state.aufdecken = 3;
        store.state.gamecomnr = 8;
          setTimeout(() => {
            showCom.value = true;
          }, 600);

      })


      emitter.on("CLOSE_GAMECOM", message => {
        console.log("CLOSE_GAMECOM", message);
        // store.state.routecnt++;
        showCom.value = false;
      })

      // setTimeout(() => {
      //   // console.log("setTimeout");
      //   // router.push( { name:"puzzle" , props: true} );
      //   router.push( {path:"/homepage/puzzle"} );
      //   // console.log("setTimeout2");
      //   // params: { "bookID": ""},  <!--  name: '/empty',-->
      //   // router.push( {path:"/homepage/puzzle"} );
      // }, 4000);

    })
    
    onBeforeUnmount(() => {
      emitter.off("GO_NEXT");
      emitter.off("GO_PUZZLE");
      emitter.off("AFTER_PUZZLE");
      emitter.off("AFTER_STOLLEN");
      emitter.off("GO_SALZPFANNE");
      emitter.off("AFTER_SALZPFANNE");
      emitter.off("AFTER_SALZPFANNEVIDEO");
      emitter.off("CLOSE_GAMECOM");
    })
    

    // watch(() =>  store.state.language, (first, second) => {
    //   console.log( "store.state.language", first, second);
    //   videoToggle.value = false;
    //   setTimeout (()=> {
    //     videoToggle.value = true;
    //   }, 300);
    // });
    
    // const startZwischenScreen = () => {
    //   console.log("startZwischenScreen")
    //   // store.state.gamecomnr=3;
    //   router.push( {path:"/homepage/empty"} );
    //   setTimeout(() => {
    //     showCom.value = false;
    //     // store.state.gamecomnr++;
    //   }, 300);
    //   setTimeout(() => {
    //     showCom.value = true;
    //     // store.state.gamecomnr++;
    //   }, 1100);
    // }
 
    const getPerfomance = () => {
      // console.log(performance.now())
      return toString(performance.now());
    }

    // const afterPuzzle = () => {
    //   console.log("afterPuzzle")
    //   router.push( {path:"/homepage/empty"} );
    //   setTimeout(() => {
    //     store.state.gamecomnr = 2;
    //     showCom.value = true;
    //   }, 600);
    // }
    // const startPuzzle = () => {
    //   console.log("startPuzzle")
    //   router.push( {path:"/homepage/puzzle"} );
    //   setTimeout(() => {
    //     showCom.value = false;
    //     // store.state.gamecomnr++;
    //   }, 300);
    // }


    // const startStollen = () => {
    //   console.log("startStollen")
    //   router.push( {path:"/homepage/stollen"} );
    //   setTimeout(() => {
    //     showCom.value = false;
    //   }, 300);
    // }

    // const startSalzpfanne = () => {
    //   console.log("startSalzpfanne")
    //   router.push( {path:"/homepage/salzpfanne"} );
    //   setTimeout(() => {
    //     showCom.value = false;
    //   }, 300);
    // }

    // const afterSalzpfanne = () => {
    //   console.log("afterSalzpfanne")
    //   router.push( {path:"/homepage/empty"} );
    //   setTimeout(() => {
    //     store.state.gamecomnr = 9;
    //     showCom.value = true;
    //   }, 600);
    // }


    // const onReady = () => {
    //   setTimeout(() => {
    //     // console.log("onReady",plyrVideo.value.player.duration)
    //     time.value = "" + toHHMMSS(plyrVideo.value.player.currentTime)  + " / " + toHHMMSS(plyrVideo.value.player.duration)
    //   }, 2000);  
    // }

    // const getVideo = () => {
    //   // vPlayCheck()
    //   // playing.value = false;
    //   console.log(publicPath + store.state.content.menus[store.state.mainMenu].intro.video[store.state.language])
    //   return publicPath + store.state.content.menus[store.state.mainMenu].intro.video[store.state.language];
    // }

    // const toHHMMSS = (secs) => {
    //     var sec_num = parseInt(secs, 10)
    //     var hours   = Math.floor(sec_num / 3600)
    //     var minutes = Math.floor(sec_num / 60) % 60
    //     var seconds = sec_num % 60

    //     return [hours,minutes,seconds]
    //         .map(v => v < 10 ? "0" + v : v)
    //         .filter((v,i) => v !== "00" || i > 0)
    //         .join(":")
    // }

    const preventClick = (e) => {
      e.stopPropagation();
    }

    // const onTimeUpdate = () => {
    //   // console.log("onTimeUpdate");
    //   // console.log(plyrVideo.value.player.currentTime);
    //   // console.log(plyrVideo.value);

    //   if (plyrVideo.value  != null) {
    //     time.value = "" + toHHMMSS(plyrVideo.value.player.currentTime)  + " / " + toHHMMSS(plyrVideo.value.player.duration)
    //   }
      
    // }

    // const onEndedAction = () => {
    //   console.log("onEndedAction")
    //   fullscreen.value = false;
    //   playing.value = false;
    // }
    const  closeGame = () => { 
      store.state.kidsGames=false;
      store.state.kidsBubble=true;
      router.push({ name:"homepage" , props: true})
    } 


    const getGameContainerStyle = () => {
      
        return {
          position: 'absolute',
          left: '0px',
          top: '0px',
          width: '1920px',
          height: '1080px',
          backgroundColor: "#ffffff",
          borderRadius: '0px',
        }

    }

    const getGameAreaStyle = () => {
      
      // console.log("getGameAreaStyle")
      
      return {
        margin: '8px 40px 0px 40px',
        width: '1840px',
        height: '1035px',
      }

    }
    
    return {
      store,
      route,
      showCom,
      getGameContainerStyle,
      getGameAreaStyle,
      closeGame,
      preventClick,
      getPerfomance,
      // startPuzzle,
      // startStollen,
      // startSalzpfanne,
    }
  },

  data() {
    return {
      loaded: "",
    };
  },

  methods: {
  
    // onError (e){
    //   console.log("onError", e)
    //   this.loaded = ""
    // },

    // onProgress(e){
    //   // console.log("onProgress", e)
    //   this.loaded = Math.floor( e.loaded / e.total * 100 )
    // },

  },

}
</script>




<style lang="scss" scoped>
@import "../css/variables.scss";  
.game-container {
    position: absolute;
    transition: 0.5s all ease;
    display: flex;
    flex-direction: column;
    // z-Index: 10;
    left: 0px;
    top: 0px;
    width: 1920px;
    height: 1080px;
    background-color: #ffffff;
    // opacity: 0.8;
    border-radius: 0px;
}

.game-area {
  // margin:  74px 80px 86px 80px;
    display: flex;
    flex-direction: column;
    transition: 0.5s all ease;
    margin: '8px 40px 60px 40px';
    width: 1840px;
    height: 1035px;
    // width: 1315px;
    // height: 740px;
    background-color: $purple;
    // opacity: 0.8;
    // background-color: rgba(255, 255, 0, 0.185);
}

.svg-close {
 height: 22px;
 width: 22px;
 margin: 6px 60px 0px 10px;

}
.site-control > p {
  font-family: FontBold;
  margin: 2px 0px 0px 0px;
  line-height: 24px;
  font-size: 24px;
  color: $purple;
  text-transform: uppercase;

}
.site-control {
  // position: absolute;
  // top: 0px;
  // left: 0px;
  // width: 100%;
  height: 40px;
  display:flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: right;
  color: black;
  // background-color: green;
  // background-color: rgba(255, 0, 0);
}

.site-left {
  position: absolute;
  top: 0px;
  left: 0px;
  // width: 100%;
  // height: 40px;
  // display:flex;
  // flex-direction: row-reverse;
  // align-items: center;
  // justify-content: right;
  color: black;
  // background-color: green;
  // background-color: rgba(255, 0, 0);
}

.animated {
  transition: all 0.7s ;
  transition-delay: 0.5s;
  // display: flex;
  // width: 100%;
}

</style>