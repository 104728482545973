<template>
  
  <section>
    <div id="zim"></div>
    <GameProgress :x="pPlaced" :y="pNum" col="#bdba2b"></GameProgress>
  </section>

</template>



<script>
// https://codepen.io/danzen/pen/rNjQWRY
import { onMounted, onUnmounted, onBeforeUnmount, ref} from "vue";

import GameProgress from "../components/GameProgress.vue"; 
import useEmitter from '@/composables/useEmitter.js'
import zim from "zimjs";
import store from "../store/global-store";
import * as createjs from 'createjs-module';


export default {

  name: "PuzzleView",

  props: {
    imageName : {
        type: String,
        required: false,
        default: "gesamtansicht_1624x920.jpg"
    }
  },

  components: {
    GameProgress
  },

  setup(props) {

    let frame;
    const emitter = useEmitter();
    let pPlaced = ref(0);
    let pNum = ref(6);
    // console.log(props.imageName)

    const gefunden = () => {
      console.log("gefunden");
      emitter.emit("CLOSE_GAMECOM", "Close message from PuzzleView" );
      createjs.Sound.play("flap");
    }

    const fertig = () => {
      console.log("fertig");
      store.state.aufdecken = 3;
      emitter.emit("AFTER_PUZZLE", "message from header" );
    }


    onMounted(() => {

      createjs.Sound.registerSound("/puppet/wing_flap.mp3", "flap");

      frame = new zim.Frame( {
      scaling:"zim", 
      width: 1800, 
      height: 1000,
      color: '#A3005F', //zim.darker, // zim.light, 
      

      ready: () => {
                // zim.zog("ready from ZIM Frame"); // logs in console (F12 - choose console)
                // often need below - so consider it part of the template
                let stage  = frame.stage;
                let stageW = frame.width;
                let stageH = frame.height;
        
                // ***************************
                // CLASS to make JigSaw piece with bumps out or in or no bump on any four edges
                class Piece extends zim.Shape {
                    // format is 1 for bump out, -1 for bump in and 0 for no bump
                    constructor(w=100,h=100,format=[1,1,1,1],s=zim.white.darken(0.2),ss=6,f=zim.white) {
                        super(w,h);
                        const p = Piece.part; // static property - defined below class
                        const g = Piece.gap;
                        this.s(s).ss(ss).f(f).mt(0,0);
                        if (format[0]==0) this.lt(w,0); // top left-right
                        else {
                            this.lt(w*p,0);
                            let s = format[0]==1?-1:1; // sign                
                            this.ct(w*(p-g/2), s*w*g, w/2, s*w*g); // curve left to middle
                            this.ct(w*(p+g+g/2), s*w*g, w*(1-p), 0); // curve middle to right           
                            this.lt(w,0)
                        }            
                        if (format[1]==0) this.lt(w,h); // right top-bottom
                        else {
                            this.lt(w,h*p);
                            let s = format[1]==1?1:-1; 
                            this.ct(w+s*w*g, h*(p-g/2), w+s*w*g, h/2);
                            this.ct(w+s*w*g, h*(p+g+g/2), w, h*(1-p));                
                            this.lt(w,h)
                        }            
                        if (format[2]==0) this.lt(0,h); // bottom right-left
                        else {
                            this.lt(w*(1-p),h);
                            let s = format[2]==1?1:-1;           
                            this.ct(w*(p+g+g/2), h+s*w*g, w/2, h+s*w*g);
                            this.ct(w*(p-g/2), h+s*w*g, w*p, h+0);                
                            this.lt(0,h)
                        }            
                        if (format[3]==0) this.lt(0,0); // left bottom-top
                        else {
                            this.lt(0,h*(1-p));
                            let s = format[3]==1?-1:1;             
                            this.ct(s*w*g, h*(p+g+g/2), s*w*g, h/2);
                            this.ct(s*w*g, h*(p-g/2), 0, h*p);             
                            this.lt(0,0)
                        }
                        this.cp(); // close path
                    }        
                }

                Piece.part = .37; // part of the edge with no gap ratio
                Piece.gap = 1- Piece.part*2; // gap ratio of edge
        
        

                // ***************************
                // PUZZLE SIZE
                let numX = 4 // 5;
                let numY = 3 // 3;
                const obj = zim.getQueryString();                     // note, getQueryString returns {} now if no query string    
                if (obj.col) numX = Math.min(14, Number(obj.col));    // or we would have to start chaching things...
                if (obj.row) numY = Math.min(10, Number(obj.row));
        


                // ***************************
                // PICTURE       
                const pic = zim.asset(props.imageName).clone().center().alp(.3).vis(false); // checkbox later to turn on
                const w = pic.width/numX;
                const h = pic.height/numY;   
                
                // chop the picture into bitmaps
                // false is for an array rather than the default Tile output 
                // extra is because bumps go outside the width and height
                const extra = Math.max(w,h)*Piece.gap; 
                const pics = zim.chop(zim.asset(props.imageName), numX, numY, false, extra); 
                    
                    

                // ****************************
                // PIECES
                
                // makePieces gets called from Tile - for each piece
                let count=0;
                let lastX = zim.rand()>.5?1:-1; // 1 or -1 for out or in horizontally
                // let lastX = zim.rand()>.5?1:-1; // 1 or -1 for out or in horizontally
                let lastYs = []; // 1 or -1 vertically - remember with array and modulus
                //zim.loop(numX, i=>{lastYs.push(zim.rand()>.5?1:-1);});


                function makePiece() {   
                    
                    // prepare format for jigsaw piece [1,0,-1,0] 
                    // 1 bump out, 0 no bump, -1 bump in, etc.
                    let currentX = lastX*-1; // opposite of last x
                    let currentY = lastYs[count%numX]*-1; // opposite of last y
                    let nextX = zim.rand()>.5?1:-1; // randomize the next 1 or -1 for out or in horizontally
                    let nextY = zim.rand()>.5?1:-1; // and vertically
                    // top, right, bottom, left
                    let format = [currentY, nextX, nextY, currentX]; 
                    lastX = nextX;
                    lastYs[count%numX] = nextY;       
                    
                    // override edges to 0
                    if (count < numX) format[0] = 0;
                    else if (count >= numX*numY-numX) format[2] = 0;
                    if (count%numX==0) format[3] = 0;
                    else if ((count-numX+1)%numX==0) format[1] = 0;
                    
                    // make a container to hold jigsaw shape and later picture part
                    let piece = new zim.Container(w,h).centerReg({add:false});
                    piece.puzzle = new Piece(w, h, format).addTo(piece);      
                    piece.mouseChildren = false;  
                    count++;
                    return piece;
                }
            
                const pieces = new zim.Tile({
                    obj:makePiece, 
                    cols:numX, 
                    rows:numY,
                    clone:false // otherwise makes clone of piece
                })
                .center()
                .drag(stage).animate({
                    props:{alpha:1},
                    time:.1,
                    sequence:.05
                });



                // ***************************
                // HINT AND SNAP HIT BOX
                // tidy up alpha setting on hint around border
                // const outline = new zim.Rectangle( pic.width, pic.height, zim.clear, zim.mist, 4).center().ord(-1) // under pieces    
                const hint = pieces.clone(true) // exact
                    .center()
                    .ord(-1) // under pieces     
                    .cache(-5,-5,pic.width+10,pic.height+10) // cache by default does not include outside border 
                    .alp(.2)
                    .vis(1); // checkbox below to show //
                
                // make a little box to do hit test to see if in right place
                const snap = 50; // pixel distance considered correct
                zim.loop(hint, h=>{
                    h.box = new zim.Rectangle(snap,snap).centerReg(h).vis(0); // do not use alpha=0 as that will make it not hittable        
                });
        


                // ***************************
                // ADD PICTURE TO PIECES, ADD EVENTS, ROTATE AND SCRAMBLE
                const padding = 50;
                const rotate = false;
                zim.loop(pieces, (piece,i)=>{
                    piece.alp(0); // sequence animation above will animate in alpha
                    pics[i].addTo(piece).setMask(piece.puzzle);  
                    // test on mobile and see if you need to cache...
                    // usually this is just cache() but the bumps are outside the piece 
                    // and the cache size really does not make a difference if rest is background transparent 
                    // if (mob) piece.cache(-100,-100,piece.width+200,piece.width+200);
                    if (rotate) {
                        piece.rotation = zim.shuffle([0,90,180,270])[0];
                        piece.tap({
                            time:.5, // within .5 seconds
                            call:() => {   
                                pieces.noMouse(); // do not let anything happen while animating until done
                                piece.animate({
                                    props:{rotation:String(frame.shiftKey?-90:90)}, // string makes relative
                                    time:.2,
                                    call:() => {
                                        pieces.mouse();
                                        test(piece);
                                    }
                                });                
                                stage.update();
                            }, 
                            call2:() => { // if no tap
                                test(piece);   
                            }                
                        }); 
                    } else {
                        piece.on("pressup", () => {
                            test(piece); 
                        });
                    }        
                    piece.on("pressdown", () => {
                        // shadows are expensive on mobile
                        // could add it to container so shadow inside container 
                        // then cache the container but might not be worth it
                        // if (!mob) piece.sha("rgba(0,0,0,.4)",5,5,5);
                    });
                    
                    // scramble location     
                    piece.loc(padding+w/2+zim.rand(stageW-w-padding*2)-pieces.x, padding+h/2+zim.rand(stageH-h-padding*2)-pieces.y);        
                    // piece.loc(i * 45 + stageW - stageW , stageH - stageH  + zim.rand() *80 -45);        
                }); 


                // ***************************
                // EMITTER
                // const emitter = new zim.Emitter({
                //     obj:new zim.Poly({min:20, max:40}, [5,6], .5, ['#65003B', '#A3005F', '#D8027F']),
                //     // obj:new zim.Poly({min:20, max:40}, [5,6], .5, [zim.orange, zim.blue, zim.green]),
                //     num:2,
                //     force:6,
                //     startPaused:true
                // });    
        
        
                // ***************************
                // MESSAGE LABEL
                const num = numX*numY;
                pNum.value = num;
                let placed = 0;
                // zim.STYLE = {color:zim.white.darken(.1), size:24}
                zim.STYLE = {color:'#A3005F', size:24}
                const stats = new zim.Label({        
                    text:`Gelöst: ${placed} / ${num}`,
                    italic: false,
                    align:zim.CENTER
                }).centerReg().pos(0,14,zim.CENTER,zim.BOTTOM);
                
                
                // ***************************
                // TEST FOR PIECE IN RIGHT PLACE AND END 
                function test(piece) {
                    piece.sha(-1);
                    let box = hint.items[piece.tileNum].box;
                    if (piece.rotation%360==0 && box.hitTestReg(piece)) {
                        piece.loc(box).bot().noMouse();
                        gefunden();
                        // emitter.loc(box).spurt(30);
                        placed++;
                        pPlaced.value = placed;
                        if (placed==num) {

                            fertig();
                            stats.text = `Toll, alle ${num} Puzzleteile platziert!`;
                            // zim.timeout(1, function () {
                            //     emitter.emitterForce = 8;
                            //     emitter.center().mov(0,-170).spurt(100)
                            // })

                            // zim.timeout(2, function () {
                            //     // hintCheck.removeFrom();
                            //     // picCheck.removeFrom();
                            //     // picCheck.checked = true;                    
                            //     pieces.animate({alpha:0}, .7);
                            //     outline.animate({alpha:1}, .7);
                            //     hint.animate({alpha:0}, .7);
                            //     pic.alp(0).animate({alpha:1}, .7);

                            //     new zim.Button({
                            //         label:"AGAIN", 
                            //         color:zim.white, 
                            //         corner:[60,0,60,0],
                            //         backgroundColor:zim.blue.darken(.3), 
                            //         rollBackgroundColor:zim.blue
                            //     })
                            //       .sca(.5)
                            //       .pos(150,30,zim.LEFT,zim.BOTTOM)
                            //       .alp(0)
                            //       .animate({alpha:1})

                            //       // normally just zgo("index.html") to reload 
                            //       // but it is different in CodePen and they have disabled document.location.reload()
                            //       // so not sure what to do... here is a the puzzle on ZIM
                            //       .tap(()=>{zim.zgo("https://zimjs.com/puzzle", "_blank");})
                            // });

                        // } else stats.text = `Placed: ${placed} piece${placed==1?"":"s"} / ${num}`;
                        } else stats.text = `Gelöst: ${placed}  / ${num}`;
                    } else stage.update();    
                }         
        

                  // ***************************
                  // CHECKBOXES AND FINISHING TOUCHES
                  // zim.Style.addType("CheckBox", {borderColor:zim.blue.darken(.3)});    
                  // const hintCheck = new zim.CheckBox(30, "hint").alp(.8).pos(50,23,zim.LEFT,zim.BOTTOM).wire({target:hint, prop:"visible", input:"checked"});
                  // const picCheck = new zim.CheckBox(30, "picture").alp(.8).pos(150,23,zim.LEFT,zim.BOTTOM).wire({target:pic, prop:"visible", input:"checked"});
                      
                  // new zim.Label("ZIM JigSaw - tap or shift tap to rotate").pos(0,30,zim.CENTER);
                  // const pixar = new zim.Label("Image from Brave © Pixar")
                  //     .sca(.5)
                  //     .pos(77,32,zim.RIGHT,zim.BOTTOM)
                  //     .hov(zim.purple)
                  //     .tap(()=>{pixar.color=zim.blue.darken(.3); zim.zgo("https://pixar.com", "_blank");});
                  // frame.madeWith().sca(.8).pos(20,12,zim.RIGHT);
                  
                  pieces.top(); // add pieces above everything
                  stage.update(); // needed to view changes

              },  // end of ready

                // assets: "brave.jpg",
        assets: props.imageName,
        path:  "/puzzles/"
      });

    }),

    onBeforeUnmount(() => {
      // console.log(frame)
      if (frame != null) {
        console.log("onBeforeUnmount")
        frame.dispose();
      }
    })

    onUnmounted(() => {
      //console.log("onUnmounted")
      zim = null;
      // emitter.off("CLOSE_GAMECOM");
      // emitter.off("AFTER_PUZZLE");
      // frame.dispose();
    });  

    return {
      pPlaced,
      pNum,
    }

  },

}
</script>



<style>

canvas {
    /* overflow-clip-margin: content-box; */
    /* overflow: clip; */
    aspect-ratio: auto 1020 / 765;
    width: 100%;
}

/* html, body, section {
  height: 100%;
  width: 100%;
}

body {
  background: whitesmoke;
} */
  
section {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
}

/* #puzzle {
  background: #CCC;
  border: 5px solid black;
  display: grid;
  grid-gap: 1px;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  height: 240px;
  width: 240px;
} */

#zim {
  width: 100%;
  height: auto;
  /* background-color: green; */
}

/* .tile{
  align-items: center;
  background: white;
  border-radius: 12px;
  cursor: pointer;
  display: flex;
  font-size: 36px;
  font-weight: bold;
  justify-content: center;
}
  
.tile.empty{
  opacity: 0;
  pointer-events: none;
}
  
.slide-move{
  transition: transform .5s ease;
} */
</style>