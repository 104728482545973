<template>
  <div>
      <!-- Hintergrund über ein Klasse definiert, Verlauf oder PNGs class="absoluteHD" v-if="isDebug()==false" -->
      <!-- <div v-if="isdDebug()==true" :style="bgDummyImage" class="absoluteHD" @wheel='handleMouseWheel' @mouseup='handleEventDown'></div> -->
      <!-- <div :style="bgDummyImage" class="absoluteHD" @wheel.passive='handleMouseWheel' @mouseup.passive='handleEventDown'></div> -->
      <div class="bgImageFinal noTouch" ></div>
  </div>
</template>



<script >
import {ref, computed, onMounted } from 'vue';
// import {useStore} from 'vuex'
// import EventBus from '../eventbus';
import useEmitter from '@/composables/useEmitter'


export default {
  name: 'VueFrameBG',
 
  setup() {  //props
  const emitter = useEmitter()
    // const store = useStore()

    let imagePos = ref(0);
    let imageArray = ref([ 
                require("../assets/demo/STARTSEITE_3_APPS_CARDS.png"),
                require("../assets/demo/VIDEO_HD1.5.png"),
                require("../assets/demo/VIDEO_HD1.5 – 1.png"),
                require("../assets/demo/KINDERAPPS AUSWAHL – 2.png"),
                require("../assets/demo/KINDERAPPS_AUSWAHL.png")
            ]);

     
    const handleMouseWheel = (event) => {
      console.log("handleMouseWheel",event);
      if (event.deltaY > 0){
          // console.log(event);
          imagePos.value >= imageArray.value.length ? imagePos.value = 0 : imagePos.value++;
          // console.log(imagePos.value);
      } else {
          // console.log(event);
          imagePos.value <= 0 ? imagePos.value = imageArray.value.length : imagePos.value--;
          // console.log(imagePos.value);
      }
     
     };

    const handleEventDown = () => {
      console.log("handleEventDown");
      // context.emit("update", "Hello World");
    };

    const bgDummyImage = computed ( function () {
        return {
          backgroundImage : 'url(' + imageArray.value[imagePos.value] + ')',
        }
      }
    )

    const bgClass = computed ( function () {
      // console.log( "bgClass");
      console.log( imageArray.value[imagePos.value]);
      return {
          // backgroundImage : '' + this.imageArray[this.imagePos] +'',
          // backgroundImage : 'url(/dummy/Impressum.jpg)',
          // backgroundImage : 'src(' + this.imageArray[this.imagePos] + ')',
          position: "absolute",
          top:"0px",
          left:"0px",
          width: "1920px",
          height: "1080px"
      } 
    })

    onMounted(() => {
      // console.log("onMounted"); // onMounted

      emitter.on("SET_MOUSEWHEEL", direction => {
          // console.log("direction", direction)
          if (direction == true){
              // console.log(event);
              imagePos.value >= imageArray.value.length ? imagePos.value = 0 : imagePos.value++;
              // console.log(imagePos.value);
          } else {
              // console.log(event);
              imagePos.value <= 0 ? imagePos.value = imageArray.value.length : imagePos.value--;
              // console.log(imagePos.value);
          }
      });

      
    });

    return {
      bgDummyImage,
      bgClass,
      handleEventDown,
      handleMouseWheel,
      // props,
    };

  }
  
 
}
</script>

<style lang="scss" scoped>
.bgImageFinal {
    background-image:url("../assets/demo/STARTSEITE_3_APPS_CARDS.png");
    position: absolute;
    top:0px;
    left:0px;
    width: 1920px;
    height: 1080px;
}

.colorHD {
    background-color: white;
    position: absolute;
    top:0px;
    left:0px;
    width: 1920px;
    height: 1080px;
    touch-action: none;
}

/* verläufe für den hintergrund  */
// .verlauf1 {
//   background-image: linear-gradient(to top, #0074bd 0%, #032d44 100%);
// }
// .verlauf2 {
// background-image: linear-gradient(to top, #e6e9f0 0%, #eef1f5 100%);
// }
// .verlauf3 {
//  background: linear-gradient(-180deg, #BCC5CE 0%, #929EAD 98%), radial-gradient(at top left, rgba(255,255,255,0.30) 0%, rgba(0,0,0,0.30) 100%);
//  background-blend-mode: screen;
// }
// .verlauf4 {
// background: linear-gradient(to bottom, #D5DEE7 0%, #E8EBF2 50%, #E2E7ED 100%), linear-gradient(to bottom, rgba(0,0,0,0.02) 50%, rgba(255,255,255,0.02) 61%, rgba(0,0,0,0.02) 73%), linear-gradient(33deg, rgba(255,255,255,0.20) 0%, rgba(0,0,0,0.20) 100%);
// background-blend-mode: normal, color-burn;
// }
// .verlauf5 {
// background-color: #dad6ce; 
//   background-image: linear-gradient(to bottom, rgba(255,255,255,0.50) 0%, rgba(0,0,0,0.50) 100%), radial-gradient(at 50% 0%, rgba(255,255,255,0.10) 0%, rgba(0,0,0,0.50) 50%); 
//   background-blend-mode: soft-light,screen;
// }

.absoluteHD {
  position: absolute;
  top:0px;
  left:0px;
  // background-color: rgb(14, 14, 238);
  width: 1920px;
  height: 1080px;
  z-index: 1000;
  opacity: 0.3;
}

</style>