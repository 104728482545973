<template>
  
  <section>

    <div id="stollen">
      
      <img class="notouch"  src="/stollen_v2/stollen_1840x1035.jpg" alt="">
      
      <img id="maus-1" @click="onCatchMaus(1)" style="mix-blend-mode: unset; position: absolute; left:743px; top: 897px;" src="/stollen_v2/salzmaus_steht.png" alt="">
      <img class="notouch"  style="mix-blend-mode: unset; position: absolute; left:303px; top: 881px; opacity: 1.0" src="/stollen_v2/boden.png" alt="">

      <img id="maus-2" @click="onCatchMaus(2)" style="mix-blend-mode: unset; position: absolute; left:742px; top: 656px" src="/stollen_v2/salzmaus_rechts.png" alt="">
      <img id="maus-3" @click="onCatchMaus(3)" style="mix-blend-mode: unset; position: absolute; left:722px; top: 656px" src="/stollen_v2/salzmaus_links.png" alt="">
      <img class="notouch"  style="mix-blend-mode: unset; position: absolute; left:724px; top: 620px; opacity: 1.0" src="/stollen_v2/eimer.png" alt="">
      
      <img id="maus-7" @click="onCatchMaus(7)" style="mix-blend-mode: unset; position: absolute; left:575px; top: 730px" src="/stollen_v2/salzmaus_links.png" alt="">
      <img class="notouch" style="mix-blend-mode: unset; position: absolute; left:388px; top: 643px; opacity: 1.0" src="/stollen_v2/kiste_links.png" alt="">
      
      <!-- <img class="notouch" style="mix-blend-mode: unset; position: absolute; left:1278px; top: 574px"  src="/stollen_v2/topf.png" alt=""> -->
      <img id="maus-4" @click="onCatchMaus(4)" style="mix-blend-mode: unset; position: absolute; left:403px; top: 790px;scale:80%;"  src="/stollen_v2/salzmaus_links.png" alt="">
      <img class="notouch" style="mix-blend-mode: unset; position: absolute; left:326px; top: 109px; opacity: 1.0" src="/stollen_v2/harke.png" alt="">

      <!-- <img id="maus-4" @click="onCatchMaus(4)" style="mix-blend-mode: unset; position: absolute; left:1457px; top: 852px" src="/stollen_v2/salzmaus_rechts.png" alt=""> -->
      <img class="notouch" style="mix-blend-mode: unset; position: absolute; left:885px; top: 9px; opacity:1.0" src="/stollen_v2/leiter.png" alt="">

      <img id="maus-5" @click="onCatchMaus(5)" style="mix-blend-mode: unset; position: absolute; left:1045px; top: 675px" src="/stollen_v2/salzmaus_rechts.png" alt="">
      <img class="notouch" style="mix-blend-mode: unset; position: absolute; left:1045px; top: 630px; opacity:1.0" src="/stollen_v2/truhe_hinten.png" alt="">

      <img id="maus-6" @click="onCatchMaus(6)" style="mix-blend-mode: unset; position: absolute; left:550px; top: 788px; scale:90%" src="/stollen_v2/salzmaus_links.png" alt="">
      <img class="notouch" style="mix-blend-mode:unset; position: absolute; left:334px; top: 53px; opacity: 1.0" src="/stollen_v2/schaufel.png" alt="">
      
      <!-- <img class="notouch"  style="mix-blend-mode: unset; position: absolute; left:624px; top: 550px"   src="/stollen_v2/truhe.png" alt=""> -->
      <!-- <img id="maus-7" @click="onCatchMaus(7)" style="mix-blend-mode: unset; position: absolute; left:813px; top: 632px"  src="/stollen_v2/salzmaus_steht_2.png" alt=""> -->

      <img id="maus-8" @click="onCatchMaus(8)" style="mix-blend-mode: unset; position: absolute; left:1087px; top: 761px" src="/stollen_v2/salzmaus_rechts.png" alt="">
      <img class="notouch" style="mix-blend-mode: unset; position: absolute; left:1085px; top: 354px; opacity: 1.0" src="/stollen_v2/kiste_rechts.png" alt="">

      <img id="maus-9" @click="onCatchMaus(9)" style="mix-blend-mode: unset; position: absolute; left:1457px; top: 932px" src="/stollen_v2/salzmaus_rechts.png" alt="">
      <img class="notouch" style="mix-blend-mode: unset; position: absolute; left:1453px; top: 192px; opacity: 1.0" src="/stollen_v2/stein.png" alt="">

      <!-- <h1 class="mousePos"> {{ useMouseMove() }} </h1> -->

      <!-- <div class="reflayer notouch">
        <div class="gameinfo notouch">
          <p> {{ store.state.content.gefangen[store.state.language] }}</p>
          <h1> {{ catchMouseList.length }} / {{ 5 }}</h1>
        </div>
        <p class="mouseinfo notouch">  {{ x }}, {{ y }} </p>
      </div> -->
      
      <GameProgress :x="catchMouseList.length" :y="5" col="#A3005F"></GameProgress>

    </div>

  </section>
</template>



<script>
import { gsap } from "gsap";
// import { createjs } from 'createjs';
import * as createjs from 'createjs-module';
// import * as createjs from 'createjs';
import GameProgress from "../components/GameProgress.vue"; 
// https://codepen.io/danzen/pen/rNjQWRY
import { ref, onMounted, onUnmounted, onBeforeUnmount} from "vue";  //  , inject,  getCurrentInstance
import useEmitter from '@/composables/useEmitter.js'
import store from "../store/global-store";

import { myRandom, myRandomInt } from "../helper/Helper.js";  //, useMouseMove
// app.config.globalProperties.$
// const myRandom = inject('myRandom');
// const myRandomInt = inject('myRandomInt');
import { useMouseMove } from '../composables/mouse.js'



export default {

  name: "StollenView",

  props: {
    imageName : {
        type: String,
        required: false,
        default: "stollen_1840x1035.jpg"
    }
  },

  components: {
    GameProgress
  },

  setup() {

    // let frame;
    // const store  = useStore();
    const emitter = useEmitter();
    const { x, y } = useMouseMove();

    const catchMouseList = ref([]);

    //const starttime = performance.now()
    // const app = getCurrentInstance
    const maxMaus = 9;
    let currMaus = 3;
    let delayRange = [1,6]
    let startPos = [];
    let tweenList = [];
    // let click = '';
    let moveMouseList = [
                        [ -50,0],
                        [ -60,0],
                        [ 70,0],
                        [ 40,0],
                        [-50,0],
                        [ 40,0],
                        [ 50,0],
                        [ -40,0],
                        [ -40,0],
                        [ -60,0]
                        ];
    let activeMouseList = [];

    // console.log(starttime)
    // const gefunden = () => {
    //   console.log("fertig");
    // }

    // const fertig = () => {
    //   console.log("fertig");
    //   store.state.aufdecken = 3;
    //   emitter.emit("AFTER_Stollen", "message from header" );
    // }


    onMounted(() => {

      // console.log("startPos", startPos.length);

      for (let index = 1; index <= maxMaus; index++) {
        // gsap.set("#maus-"+ index, {transformOrigin:"center center"});
        let x = gsap.getProperty("#maus-" + index, "x");
        let y = gsap.getProperty("#maus-" + index, "y");
        //console.log(x,y);
        if (x!=0 || y!=0) {
          gsap.to("#maus-" + index,  { duration: 0.0, delay: 0, x: 0, y: 0});
          x = gsap.getProperty("#maus-" + index, "x");
          y = gsap.getProperty("#maus-" + index, "y");
          // console.log("korrigiert", x,y);
        }
        startPos.push([x,y]);
      }

      // console.log("startPos", startPos.length, startPos[0][0],startPos[0][1]);

      let delay = myRandom(delayRange[0],delayRange[1])

      // console.log("onMounted StollenView", starttime);
      // gsap.set("#maus-1", {transformOrigin:"center center"});
      
      let tween = gsap.to("#maus-" + currMaus,  { duration: 1.0, delay: delay, x: moveMouseList[currMaus-1][0], y: moveMouseList[currMaus-1][1], onComplete:nextMaus});
      tweenList.push(tween);
      activeMouseList.push(currMaus);

       // createjs.Sound.on("fileload", handleLoad); 
      createjs.Sound.registerSound("/puppet/click.mp3", "click");
      // createjs.Sound.play("click");
      // click = createjs.Sound.createInstance("click");
      // click.play()

    })

    // const myRandom = (min, max) => {
    //     return Math.random() * (max - min) + min;
    // }
    // const myRandomInt = (min, max) => {
    //     return Math.floor(Math.random() * (max - min) + min);
    // }

    const nextMaus = () => {

      // remove maus auf alte Position
      let delay = 0.5;
      let tween = gsap.to("#maus-" + currMaus, { duration: 0.5, delay: delay, x: startPos[currMaus-1][0], y: startPos[currMaus-1][1], onComplete:removeActiveMaus, onCompleteParams: [currMaus] } );
      tweenList.push(tween);
      // und die neue setzen
      
      // step durch die mäuschen der reihe nach
      // currMaus >= maxMaus ? currMaus = 1 : currMaus++ ;

      // neue maus
      newMaus ();

    }

    // getrenntes starten einen neuen maus
    const newMaus = () => {
      let newMaus = myRandomInt(1,10)
      // console.log(newMaus, currMaus, catchMouseList.value.indexOf(newMaus));

      while ( (newMaus == currMaus) || (catchMouseList.value.indexOf(newMaus) >= 0 )) {
        // console.log(newMaus,currMaus, catchMouseList.value.indexOf(newMaus));
        newMaus = myRandomInt(1,10);
      }
      currMaus = newMaus;
      // console.log("currMaus", currMaus);
      activeMouseList.push(currMaus);

      // console.log("currMaus", currMaus);
      let delay = myRandom(delayRange[0],delayRange[1])
      let tween = gsap.to("#maus-" + currMaus, { duration: 1.0, delay: delay,  x: moveMouseList[currMaus-1][0], y: moveMouseList[currMaus-1][1], onComplete:nextMaus});
      tweenList.push(tween);
    }

    const onCatchMaus = (val) => {
        // console.log("onCatchMaus", val);
        // console.log("activeMouseList", activeMouseList);

        if (activeMouseList.indexOf(val)>=0) { // nur anklickbar wenn maus aktiv
          // console.log(tweenList);
          tweenList[tweenList.length-1].pause();
          if (tweenList.length> 1){
            tweenList[tweenList.length-2].pause();
          }
          createjs.Sound.play("click");

          catchMouseList.value.push(val);
          // console.log(catchMouseList.value);
          emitter.emit("CLOSE_GAMECOM", "Close message from StollenView" );
          removeActiveMaus(val);

          if (catchMouseList.value.length >= 5) {
            setTimeout(() => {
              store.state.aufdecken = 3;
              emitter.emit("AFTER_STOLLEN", "message from StollenView");
            }, 1000);
            
          } else {
            setTimeout( () => {
              newMaus();
            }, 2000);
            
          }
        }
    }

    const removeActiveMaus = (val) => {
      // console.log("removeActiveMaus", val);
      // console.log("activeMouseList", activeMouseList);

      if (activeMouseList.indexOf(val) >= 0){
        // console.log("removeActiveMaus found", val);
        let mysplit = activeMouseList.indexOf(val);
        // console.log("mysplit", mysplit);
        activeMouseList.splice(mysplit, 1)
      }
      // console.log("activeMouseList", activeMouseList);
    }
  
    onBeforeUnmount(() => {
      // for (let index = 1; index <= maxMaus; index++) {
      //   gsap.killTweensOf("#maus-" + index-1);
      // }
      tweenList.forEach(element => {
        element.kill();
      });  
    })

    onUnmounted(() => {
      // console.log("onUnmounted StollenView")
    });  

    return {
      store,
      x,
      y,
      catchMouseList,
      onCatchMaus,
      // useMouseMove,
    }

  },

  // watch: {
  //   // solved(newVal, oldVal) {
  //   //   console.log(newVal, oldVal);
  //   //   alert('Solved.')
  //   // }
  // },
  // computed: {
  //   // empty() {
  //   //   return this.tiles.findIndex(tile => tile === '9')
  //   // },
  //   // solved() {
  //   //   return _.isEqual(this.tiles, this.solution)
  //   // }
  // },
  // methods: {
  //   // move(i) {
  //   //   if (this.moves[i].includes(this.empty)) {
  //   //     let tileIndex  = i
  //   //     let tile       = this.tiles[i]
  //   //     let emptyIndex = this.empty
  //   //     this.$set(this.tiles, tileIndex, this.tiles[emptyIndex])
  //   //     this.$set(this.tiles, emptyIndex, tile)
  //   //   }
  //   // }
  // }
}
</script>



<style>

/* html, body, section {
  height: 100%;
  width: 100%;
}

body {
  background: whitesmoke;
} */
  
/* section {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
} */

/* #puzzle {
  background: #CCC;
  border: 5px solid black;
  display: grid;
  grid-gap: 1px;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  height: 240px;
  width: 240px;
} */

#stollen {
  /* position: relative; */
  width: 1840px;
  height: 1035px;
  display: flex;
  flex-direction: column;
  justify-items: center;
  justify-content: center;
  /* background-color: green; */
}


.notouch {
  /* position: absolute; */
  /* width: 1840px; */
  /* height: 1035px; */
  pointer-events: none;
}

.mouseinfo {
  /* position: absolute;
  left: 50px;
  bottom: 10px; */
  color: white;

  align-self: center;
  /* justify-self: end; */
  margin-top: auto;
  opacity: 0.0;
  /* justify-self: end; */
  /* align-items: baseline; */
}

.reflayer {
  position: absolute;
  /* background-color: aqua; */
  /* opacity: 0.5; */
  width: 1840px;
  height: 1035px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  /* justify-content: center; */
}


.gameinfo {
  /* position: relatve; */
  /* top: 20px;
  left: 400px; */

  width: 220px;
  height: 140px;
  margin-top: 40px;

  background-color: white;
  border-radius: 30px;
  filter: drop-shadow(3px 3px 5px #353535);

  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  
  align-self: center;
  
}

.gameinfo > p {
  color:black;
  
  /* background-color: green; */
  text-align: center;
  padding: 0px;
  margin: 0px;
}

.gameinfo > h1 {
  color: #A3005F;
  /* background-color: blue; */
  text-align: center;
  font-size: 60px;
  line-height: 60px;
  margin-top: 10px;
}

</style>../Helper