<template>
    
    <div style="position:absolute;">

          <div class="intro-container">

            <div class="flex-row">

             <div class="flex-item-1">
                 <!-- 
                  <h4> {{  store.state.contentUi.impressum[store.state.language] }}</h4>
                  <h2 >Förderverein</h2>
                  <div class="deko__line"></div>
                <div class="text-container">
                  <img :src="base_url +  'images/foerderverein.jpg'" alt="">
                </div> 
                -->
              </div> 

              
              <div class="flex-item-2">

                <h4> Impressum</h4>
                <h2 >Digitale Präsentation</h2>
                <div class="deko__line"></div>

                <div class="text-container">
                  
                  <div>

                    <p>

                      <strong>Die Saline Gottesgabe in Rheine – wie die Salzgewinnung früher funktionierte</strong><br>
                      Ein Projekt des Vereins zur Förderung der Saline Gottesgabe e.V., Rheine in Kooperation mit den Städtischen Museen Rheine.<br><br>
                      
                      <strong>Kontakt</strong><br>
                      Tel.: 0170 5856820<br> 
                      E-Mail: info@saline-gottesgabe.de<br>
                      Website: www.saline-gottesgabe.de<br><br>

                    </p>

                    <img :src="base_url +  'images/foerderverein.jpg'" alt="">

                    <p>
                      <br>Eingetragen beim Amtsgericht Steinfurt VR 1231<br><br>
                      Gefördert durch die Nordrhein-Westfalen-Stiftung Naturschutz, Heimat- und Kulturpflege<br><br>
                    </p>

                    <img style="transform-origin: left top; transform: scaleX(50%) scaleY(50%);" :src="base_url +  'images/NRW-Stiftung-Logo.png'" alt="">
                    

                    <p>
                      Konzeption und inhaltliche Ausarbeitung: Oliver Raß M. A., Städtische Museen Rheine<br> 
                      3D-Visualisierungen: Jochen Stuhrmann<br> 
                      Produktion: bluelemon Interactive OHG<br><br> 
                    </p>
                      
                    <p>
                      Für Genehmigungen, Bereitstellungen und freundliche Unterstützung danken wir:<br>
                      Edmund Ballhaus<br> 
                      Technische Informationsbibliothek Hannover (TIB)<br> 
                      Niels Hansen<br> 
                      Heinz Schulte<br>
                      LWL-Medienzentrum für Westfalen<br><br>
                      © Verein zur Förderung der Saline Gottesgabe e.V. und beteiligte Urheber. Alle Rechte vorbehalten.
                    </p>
                      

                  </div>

                </div>
                  
              </div>

            </div>

          </div>
    </div>    
    
</template>



<script>
// https://glb-packer.glitch.me/  packt den kram zusammen
import { ref,  onMounted } from 'vue'; 
// import { useRouter } from 'vue-router'  
import { useStore } from 'vuex'
import { publicPath } from '../../vue.config';


export default {

  name: "ImpressumView",

  inheritAttrs: false,

  setup() {
    const store = useStore();
    // const router = useRouter();
    let url = ref('');
    // const showBible = ref (false);
    var base_url = window.location.origin +"/" + publicPath

    onMounted(() => {
      // setTimeout(() => {
      //   showBible.value = true;
      // }, 600)

      // setTimeout(() => {
      //   camera = this.$refs.camera.camera;
      //   renderer = this.$refs.renderer;
      // }, 1000)
    })

    

      return {
        store,
        url, 
        base_url
      }
  },

  data() {
    return {
      
    };
  },

  mounted() {
    
  },

  methods: {
    
  },

}
</script>



<style lang="scss" scoped>
@import "../css/variables.scss";

.intro-container {
     position: absolute;
     left: 139px;
     top: 170px;
     width: 1642px;
     height: 690px;
     z-index: 0;
     background-color: #ffffff;
     border-radius: 30px;
}


// position: absolute;
//      left: 510px;
//      top: 270px;
//      width: 2812px;
//      height: 1640px;
//      z-index: 0;
     


.flex-row {
  display: flex;
  flex-direction: row;
  // background-color: yellow;
}

.flex-item-1 {
    height: 636px; 
    width: 801px;
    margin-top: 20px;
    margin-left: 63px;
    // width: 1192px;
    // background-color: darkorchid;
}

.flex-item-2 {
    height: 636px; 
    width: 801px;
    // width: 1784px;
    margin-top: 20px;
    // margin-left: 126px;
    // background-color: rgb(63, 204, 50);
}
// .bgbibel {
//     background: #1E211D;
//     // background: radial-gradient(#fff 20%, #b9b9b9 100%);
// }

.text-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 403px;
  padding: 5px;
  font-family: FontLight;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: black;
  backdrop-filter: blur(14px) brightness(40%);
}

.flex-row-small {
  display: flex;
  flex-direction: row;
  margin-top: 25px;
  margin-bottom: 25px;
  // background-color: yellow;
}
.flex-row-one {
  display: flex;
  flex-direction: row;
  margin-top: 5px;
  margin-bottom: 5px;
}

.main-image {
  margin-left: 40px;
  margin-top:40px;
}

// .sub-image {
//   margin-right: 120px;
// }

.text-container {
  overflow-x: hidden;
  overflow-y: auto;
  height: 522px;
  width: 734px;
}

.text-container h2 {
  margin-top: 0px;
  font-family: FontBold;
  letter-spacing: 0px;
  font-size: 24px;
  line-height: 33px;
  font-weight: normal;
  margin-bottom: 6px;
  color: $black;
}

h4 {
  font-family: FontRegular;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0px;
  text-transform: uppercase;
  font-weight: normal;
  margin-bottom: 4px;
  color: $black;
}

.text-container p {
  // margin-left: 60px;
  margin-top: 12px;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 12px;
  width: 704px;

}

// .text-container img {
//   margin-right: 100px;
//   // width: 1000px;
// }

// .flex-row-one p {
//   width: 1880px;
// }
.text-container::-webkit-scrollbar {
    width: 2px;
}

.text-container::-webkit-scrollbar-track {
  background-color: $trackcolor

}

.text-container::-webkit-scrollbar-thumb {
    background-color: $thumbcolor;
    background-clip: padding-box;
    // border: 0.4rem solid rgba(0, 255, 0, 0.5); // translucent green used to aid development, set to 'transparent' in production
    // transform: scale(4);
}

</style>