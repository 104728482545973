<template>
    
    <div style="position:absolute;">

        <!-- @ended="onEndedAction()"  :data-poster="propPoster" -->
        <!-- <track default kind="captions" label="English captions" src="/videos/projektor_v3.vtt" srclang="en"  /> -->

        <!-- <transition name="fadepageslow" mode="out-in" appear :key="store.state.language" @after-enter="onAfterEnter"> -->
        <div class="video-container" :style="getVideoContainerStyle()">
           <!--appear-->
          <!-- <transition name="fadepage" mode="out-in"  @after-enter="onAfterEnter">  -->
          <div class="video-area" :style="getVideoAreaStyle()" @click.passive="vPlayCheck('video')" v-if="videoToggle==true" :key="store.state.language">  
             
            <vue-plyr :options="options" ref="plyrVideo" @timeupdate="onTimeUpdate" @ended="onEndedAction" @ready="onReady">  
                <video
                  crossorigin
                  playsinline
                  autoplay
                  :poster="propPoster"
                > 
                  <source
                        size="1080p"
                        :src="getVideo()"
                        type="video/mp4" 
                  />
                </video>
            </vue-plyr>  
           
            <div class="video-control" >

                <div class="video-control__play" :style="videoIsPlaying()" ></div> <!--:style="videoPlayBigStyle()"-->
                <div class="video-control__footer" :style="videoControlFooterStyle()"  @click.passive="preventClick($event)" >
                    
                    <div class="video-btn-element">
                      <transition name="fadepage" mode="out-in" appear>
                          <img src="../assets/svgs/play-btn.svg" alt="" v-if="playing==false" @click.passive="vPlayCheck('video')">
                          <img src="../assets/svgs/pause-btn.svg" alt="" v-else-if="playing==true" @click.passive="vPlayCheck('video')">
                      </transition>
                    </div>
                    
                    <!-- <div class="video-btn-element">
                      <img src="../assets/svgs/video-makebig.svg" alt=""> 
                    </div> -->
                    
                    <div class="video-btn-element" :style="showTimeStyle()" style="{opacity: 0.0; transition: 1s all ease;}" > 
                      <p> {{ time }} </p>
                    </div>

                    <div class="video-btn-element" @click.passive="doFullScreen($event)">
                      <transition name="fadepage" mode="out-in" appear>
                          <img src="../assets/svgs/video-makebig.svg" alt="" v-if="fullscreen==false">
                          <img src="../assets/svgs/video-makesmall.svg" alt="" v-else-if="fullscreen==true">
                      </transition>
                    </div>

                </div>

            </div>

          </div>
          <!-- </transition> -->

        </div> 
        <!-- </transition> -->

    </div>
    
</template>



<script>
// https://glb-packer.glitch.me/  packt den kram zusammen
// plyr: https://github.com/sampotts/plyr
import { ref, onMounted, watch } from 'vue';  // reactive, 
import { useRouter } from 'vue-router'  
import { useStore } from 'vuex'
import { publicPath } from '../../vue.config';


export default {
  inheritAttrs: false,

  components: {      
  },

  props: {
    // propPoster:  {
    //   default: "/videos/PaterNoster_v08_HD.jpg"
    // },
    // propVideo: {
    //   default: "/videos/PaterNoster_v08_HD.mp4"
    // } 
  },

  setup() {
    const store = useStore();
    const router = useRouter();
    let url = ref('');
    let fullscreen = ref(false);
    let plyrVideo = ref();
    let playing = ref(true);
    let options = ref( { 
        quality: { default: '1080p' },
        controls: [], // 'play', 'progress','current-time' // 'play-large', 'fullscreen'
    })
    let time = ref("")
    let propPoster = publicPath + store.state.content.menus[store.state.mainMenu].intro.image  // "/videos/PaterNoster_v08_HD.jpg"
    let propVideo = ref(store.state.content.menus[store.state.mainMenu].intro.video[store.state.language])  // "/videos/PaterNoster_v08_HD.mp4"
    // let video = store.state.content.menus[store.state.mainMenu].intro.video;
    // console.log(video);
    let videoToggle = ref(true)

    onMounted(() => {

      // plyrVideo.value.player.on('ended', () => this.onEndedAction())
      // plyrVideo.value.player.on('timeupdate', () => this.onTimeUpdate())

      // plyrVideo.value.player.on('play', () => this.onVideoPlay())
      // setTimeout(() => {
      //   showBible.value = true;
      // }, 600)

      // setTimeout(() => {
      //   camera = this.$refs.camera.camera;
      //   renderer = this.$refs.renderer;
      // }, 1000)

     
    })

    watch(() =>  store.state.language, (first, second) => {
      console.log( "store.state.language", first, second);
      videoToggle.value = false;
      setTimeout (()=> {
        videoToggle.value = true;
      }, 300);
    });

    const onAfterEnter = () => {
      console.log("onAfterEnter")
      playing.value = true;
      onReady();
    }

    const onReady = () => {
      setTimeout(() => {
        // console.log("onReady",plyrVideo.value.player.duration)
        if (plyrVideo.value != null) {
          if (plyrVideo.value.player != null) {
            time.value = "" + toHHMMSS(plyrVideo.value.player.currentTime)  + " / " + toHHMMSS(plyrVideo.value.player.duration);
          }
        }
        // time.value = "" + toHHMMSS(plyrVideo.value.player.currentTime)  + " / " + toHHMMSS(plyrVideo.value.player.duration);
      }, 2000);  
    }

    const getVideo = () => {
      // vPlayCheck()
      // playing.value = false;
      // console.log(publicPath + store.state.content.menus[store.state.mainMenu].intro.video[store.state.language]);
      return publicPath + store.state.content.menus[store.state.mainMenu].intro.video[store.state.language];
    }

    const toHHMMSS = (secs) => {
        var sec_num = parseInt(secs, 10)
        var hours   = Math.floor(sec_num / 3600)
        var minutes = Math.floor(sec_num / 60) % 60
        var seconds = sec_num % 60

        return [hours,minutes,seconds]
            .map(v => v < 10 ? "0" + v : v)
            .filter((v,i) => v !== "00" || i > 0)
            .join(":")
    }

    const preventClick = (e) => {
      e.stopPropagation();
    }

    const onTimeUpdate = () => {
      // console.log("onTimeUpdate");
      // console.log(plyrVideo.value.player.currentTime);
      // console.log(plyrVideo.value);

      if (plyrVideo.value  != null) {
        time.value = "" + toHHMMSS(plyrVideo.value.player.currentTime)  + " / " + toHHMMSS(plyrVideo.value.player.duration)
      }
      
    }

    const onEndedAction = () => {
      console.log("onEndedAction")
      fullscreen.value = false;
      playing.value = false;

      setTimeout(() => {
        // closeVideo();
      }, 1000);
    }

    const closeVideo = () => {
          store.state.page = 0;
          // store.state.book = -1;
          store.state.hideBerge = false;
          store.state.impressum = false;
          store.state.kidsBubble = true;
          store.state.kids = false;
          store.state.kidsMenu = -1;
          router.push({ name: 'homepage',  props: true})  // params: { "bookID": ""}, 

    }

    const getVideoContainerStyle = () => {
      
      if (fullscreen.value == true) {
        // console.log("getVideoContainerStyle true")
        return {
          left: '0px',
          top: '0px',
          width: '1920px',
          height: '1080px',
          zIndex: 1,
          // backgroundColor: "#ffff00",
          borderRadius: '0px',
        }

      } else {
        // console.log("getVideoContainerStyle false")
        return {
          left: '255px',
          top: '135px',
          width: '1395px',
          // width: '1406px',
          height: '820px',
          zIndex: 0,
          // backgroundColor: "#ff0000",
          borderRadius: '30px',
        }

      }
    }

    const getVideoAreaStyle = () => {
      
      if (fullscreen.value == true) {
        // console.log("getVideoAreaStyle true")
        // return {
        //   margin: '8px 40px 60px 40px',
        //   width: '1840px',
        //   height: '1035px',
        // }
        return {
          margin: '0px 0px 0px 0px',
          width: '1920px',
          height: '1080px',
        }


      } else {
        // console.log("getVideoAreaStyle false")
        return {
          margin: '37px 40px 43px 40px',
          width: '1315px',
          height: '740px',
          // margin: '10px 10px 10px 10px',
        }

      }
    }
    
    const videoIsPlaying = () => {
      
      // console.log(plyrVideo.value);

      // if (plyrVideo.value != undefined) {
        // console.log("videoIsPlaying");
        if (playing.value == true && fullscreen.value == true){
            return {
                opacity: 0.0,
                marginTop: '500px',
            }

        } else if (playing.value == true && fullscreen.value == false){
            return {
                opacity: 0.0,
                marginTop: '380px',
            }

        } else if (playing.value == false && fullscreen.value == false){
            return {
                opacity: 1.0,
                marginTop: '380px',
            }

          } else if (playing.value == false && fullscreen.value == true){
            return {
                opacity: 1.0,
                marginTop: '500px',
            }
        }
      // }
      
    }

    // const videoPlayBigStyle = () => {
    // if (fullscreen.value == true) {
    //     // console.log("getVideoAreaStyle true")
    //     return {
    //       marginTop: '960px'
    //     }
    //   } else {
    //     // console.log("getVideoAreaStyle false")
    //     return {
    //       marginTop: '760px'
    //     }
    //   }
    // }

    const vPlayCheck = () => {

        // console.log(plyrVideo.value.player.playing);

        if (plyrVideo.value.player.playing == true){
          plyrVideo.value.player.pause()
          playing.value = false;
        } else {
          plyrVideo.value.player.play();
          playing.value = true;
        }
        // this.$refs.plyrvideo.player.stop()
        // this.$refs.plyrvideo.player.togglePlay()
        // console.log(plyrVideo.value.player.playing);
        // console.log("________________________");
    }

    const showTimeStyle = () => {
      if (time.value == 0) {
        return {
          opacity: 0.0,
          transition: '0.5 opacity ease',
        }

      } else {
        return {
          opacity: 1.0,
          transition: '0.5 opacity ease',
        }

      }
    }

    const doFullScreen = (event) => {
      // console.log("doFullscreen")
      console.log(plyrVideo.value.player.fullscreen.active)
      // plyrVideo.value.player.fullscreen.toggle()
      // fullscreen.value = true;
      fullscreen.value = !fullscreen.value
      event.stopPropagation()
      // options.value.controls = ['fullscreen']
    }

    const videoControlFooterStyle = () => {
      if (fullscreen.value == false) {
        return {
          width: '1320px',
          height: '40px',
        }

      } else {
        return {
          width: '1920px',
          height: '40px',
          backgroundColor: '#ffffff99',
        }

      }
    }

      return {
        store,
        url,
        plyrVideo,
        options,
        playing,
        fullscreen,
        time,
        propPoster,
        propVideo,
        videoToggle,
        vPlayCheck,
        doFullScreen,
        getVideoContainerStyle,
        getVideoAreaStyle,
        videoIsPlaying,
        videoControlFooterStyle,
        showTimeStyle,
        onEndedAction,
        onTimeUpdate,
        onReady,
        preventClick,
        onAfterEnter,
        getVideo,
        closeVideo,
      }
  },

  data() {
    return {
      loaded: "",
    };
  },

  methods: {
  
    onError (e){
      console.log("onError", e)
      this.loaded = ""
    },

    onProgress(e){
      // console.log("onProgress", e)
      this.loaded = Math.floor( e.loaded / e.total * 100 )
    },

  },

}
</script>



<!-- <style lang="scss">
video::-webkit-media-controls-panel {
    display: flex !important;
    opacity: 1 !important;
}
</style> -->



<style lang="scss" scoped>
@import "../css/variables.scss";  
.video-container {
    position: absolute;
    transition: 0.5s all ease;
    background-color: white;
    //  left: 510px;
    //  top: 270px;
    //  width: 2812px;
    //  height: 1640px;
    //  z-index: 0;
    //  background-color: #ffffff;
    //  border-radius: 60px;
}

.video-area {
  // margin:  74px 80px 86px 80px;
    display: flex;
    flex-direction: column;
    transition: 0.5s all ease;
    // width: 1315px;
    // height: 740px;
    background-color: pink;
}

// .video-control {
//   position: absolute;
//   top: 0px;
//   left: 0px;
//   width: 100%;
//   height: 100%;
//   display:flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   // background-color: rgba(255, 255, 0, 0.185);
// }

.video-control  {
    position: absolute;
    // background-color: orange;
    bottom: 1px;
    left: 0;
    width: 100%;
    height: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.video-control__play {
  background-image: url("../assets/svgs/play-btn-big.svg");
  background-size: contain;
  background-size: cover;
  background-size: 100%;
  // background-color: orange;
  height: 60px;
  width: 60px;
  // margin-top: 760px;
  align-self: center;
  justify-self:auto;
  transition: 0.6s all ease;
}

.video-control__footer {
  // background-image: url("../assets/svgs/play-btn-big.svg");
  // background-color: rgba(255, 166, 0, 0.295);
  height: 40px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  // width: 2660px;
  // align-self: center;
  vertical-align: center;
  // justify-self:bottom;
  margin-top: auto;
  align-content: center;
  align-items: center;
  transition: 0.6s width ease;
}

.video-btn-element {
  display: flex;
  justify-self: center;
  align-content: center;
  justify-content:center;
  width: 120px;
  height: 25px;
  // background-color: red;
}

.video-btn-element p {
  font-family: FontBold;
    font-size: 20px;
    line-height: 20px;
    color: $purple;
    margin-bottom: 0px;
    margin-top: 0px;
    white-space: pre-line;
    width: 200px;
}
// .video-js {
//   width: 1200px;
//   height: 360px;
// }
  
.video-js.video-js .vjs-big-play-button {
  background-color: #000000;
  opacity: .7;
  width: 50px;
  height: 40px;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  margin: auto;
  border-radius: 5px;
  border-style: solid;
  border-width: 1x;
  border-color: #FFFFFF;
}
/* 
  This rule sets the location and size
  of the triangular play icon in the
  big play button 
*/
.video-js.video-js .vjs-big-play-button:before {
  margin-top: 13px;
  margin-left: 15px;
  font-size: 2em;
  color: white;
  opacity: .9;
}

.flex-row {
  display: flex;
  flex-direction: row;
  // background-color: yellow;
}

.flex-item-1 {
    height: 690px; 
    width: 596px;
    // background-color: darkorchid;
}
.flex-item-2 {
    height: 690px; 
    width: 892px;
    margin-top: 27px;
    // background-color: rgb(63, 204, 50);
}

#output {
  height: 50px; 
  width: 100px;
  background-color: green;
  z-index: 2000;
}

.main-image {
  margin-left: 40px;
  margin-top: 40px;
}

.c1 {
	// background: blue;
  // height: 300px;
  position: relative;
}

.menu-flex {
  position: absolute;
  left: 594px;
	bottom: 63px;
  display: flex;
  flex-direction: row;
}

.menu-btn {
  display: flex;
  flex-direction: row;
  margin-right: 40px;
  justify-content: space-between;
  background-color: #94a78f41;
  padding: 0 10px;
}

.menu-btn > p {
  font-family: FontSerifItalic;
  font-size: 16px;
  line-height: 24px;
  margin-right: 15px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.menu-btn > img {
  margin-right: 10px;
  -webkit-transform: scaleX(1.5) scaleY(1.5);
  transform: scaleX(1.5) scaleY(1.5);
}
</style>