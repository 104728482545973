<template>

    <div>

        <div class="floater">
            <div style="margin-left: 140px; margin-top: 0px; padding-left: 0px;">
                <!-- <h3>{{store.state.content.kids.gamecom[store.state.gamecomnr].head}} // {{ store.state.gamecomnr }}</h3> -->
                <p v-html="store.state.content.kids.gamecom[store.state.gamecomnr].copy[store.state.language]"></p>
                <img class="salzmaus" src="../assets/svgs/Salzmaus_steht.svg" alt="">
            </div>
           
            <div class="btn" @click="goNext()" v-if="store.state.content.kids.gamecom[store.state.gamecomnr].button[store.state.language] !='' && store.state.kidsMenuGame==0">
                <img src="../assets/svgs/pfeil.svg" alt="">
                <p>{{ store.state.content.kids.gamecom[store.state.gamecomnr].button[store.state.language] }}</p>
            </div>

            <div class="btn" @click="goNext()" v-else-if="store.state.content.kids.gamecom[store.state.gamecomnr].button[store.state.language] !='' && store.state.content.kids.gamecom[store.state.gamecomnr].buttonshow==true">
                <img src="../assets/svgs/pfeil.svg" alt="">
                <p>{{ store.state.content.kids.gamecom[store.state.gamecomnr].button[store.state.language] }}</p>
            </div>

        </div>
        
        <div class="kidsmenu" v-if="store.state.gamecomnr>=11" @click="goKidsMenu()">
            <p>{{ store.state.content.spielemenu[store.state.language] }}</p>
        </div>
    </div>

</template>



<script>
import useEmitter from '@/composables/useEmitter.js'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'  // , useRoute


export default {
    
    name: "GameCom",

    // props: {
    //   contentui: Object,
    //   language: String
    // },

    // components: {
    // },  

    setup() { // props
        const store  = useStore();
        const emitter = useEmitter();
        const router = useRouter();
        // const startTimer = performance.now();
       
        const goNext = () => {
            // console.log("GameCom goNext", startTimer)
            emitter.emit("GO_NEXT", ""  );  // ",message " + startTimer
        }

        const goKidsMenu = ( ) => {
                console.log("goKidsMenu");
                store.state.page = 1;
                store.state.hideBerge = false;
                store.state.impressum = false;
                // store.state.KidsGames = false;
                store.state.kidsGames = false;
                store.state.kidsMenu = 1;
                store.state.kids = false;
                // store.state.kidsBubble = true;
                router.push({ path: "/kidsmenupage" + "" })
        }


        return {
            goNext,
            goKidsMenu,
            store
        }
    }

}
</script>



<style scoped>
.floater {
    position: relative;
    left: 630px;
    top: 760px;
    background-color: rgba(244, 244, 244, 1.0);
    
    z-index: 10000;

    display: flex;
    flex-direction: column;
    flex: 1;
    
    width: 660px;
    height: 220px;
    /* z-index: 13; */
    
    border-radius: 30px;
    padding: 20px;
}

.kidsmenu {
    position: relative;
    left: 100px;
    top: 540px;
    background-color: #A3005F;
    height: 220px;
    width: 220px;
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    flex: 1;
    vertical-align: auto;
    align-self: center;
    border-radius: 30px;
    padding: 20px;
}

.kidsmenu p {
    color: white;
    text-align: center;

}

h3 {
    font-family: FontBold;
    color: black;
    font-size: 44px;
}

.salzmaus {
    position: absolute;
    top: -15px;
    left: -110px;
    transform: scaleX(-0.75 ) scaleY(0.75);
    align-items: left;
}

.btn {
    position: absolute;
    display: flex;
    flex-direction: row-reverse;
    height: 40px;
    /* background-color: rgb(148, 148, 148); */
    /* justify-content: space-between; */
    bottom: 20px;
    right: 30px;
}

.floater p {
    line-height: 40px;
    width: 480px;
    /* background-color: green; */
    margin: 0px;
    padding: 0px;
}

.btn > p {
    /* background-color: green; */
    font-family: FontSerifItalic;
    width: auto;
    /* background-color: white; */
    margin: 0px;
    padding: 0px;
    font-size: 32px;
    line-height: 44px;
}

.btn > img {
    padding-left: 20px;
    /* height: 40px; */
}

</style>