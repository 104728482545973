<template>
    
    <footer class="main-footer" >
        
        <div class="main-header__brand" :style="showBerge()">
            <a @click.passive="goHome()">
                <img class="salzberg" :src="kSImage()" alt="logo">
            </a>

            <div class="verein" :style="showVerein()">
                <img src="../assets/img/verein.jpg" alt="" style="opacity: 0.0" @dblclick.passive="goKidsMenu()">
            </div>

            <div class="arrow" :style="showArrow()"  @click.passive="goHome()">
              <div class="demo-arrow-cls up" :style="pfeilColor1()"></div>
              <div class="line-arrow" :style="pfeilColor2()"></div>
            </div>
        </div>


        <div class="flex-menu">
             
            <transition name="fadepage" mode="out-in" appear>
              <div class="menu-btn" @click.passive="goHome()" v-if="store.state.page>0" :key="store.state.page">
                <h4 v-html="store.state.content.backtitel[store.state.language]"></h4>
                <img class="img-mirror deselect" src="../assets/svgs/pfeil-gross.svg" alt="">
              </div>
            </transition>

            <!-- <transition name="fadepage" mode="out-in" appear>  
              <div class="menu-btn" @click.passive="goKidsBubbleMenu()" v-if="store.state.kidsMenu>-1 && store.state.kids==true" :key="store.state.kidsMenu">
                <h4 v-html="store.state.content.kids.backtitel[store.state.language]"></h4>
                <img class="img-mirror deselect" src="../assets/svgs/pfeil-gross.svg" alt="">
              </div>
            </transition>  -->

        </div>


        <transition name="fadepage" mode="out-in" appear>
        <div class="impressum-menu" v-if="store.state.impressum==false" :key="store.state.impressum">
            <div class="impressum-btn" @click.passive="goImpressum()">
              <!-- <p v-html="store.state.content.impressum[store.state.language]"></p>   -->
              <h4 v-html="store.state.content.impressum[store.state.language]"></h4>  
              <img class="img-normal deselect" src="../assets/svgs/pfeil-gross.svg" alt="">
            </div>
        </div>
        </transition>

        <div class="language-menu">
            <div class="language-btn" @click.passive="goLanguage($event)">
                  <h4>{{ store.state.content.sprachauswahl[store.state.language] }}</h4>
                  <p> DE&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;EN&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;NL </p>
                  <!-- <img class="img-normal deselect" src="../assets/svgs/pfeil-gross.svg" alt=""> -->
                  <div class="lg-line" :style="languagePos()"></div>
            </div>  
        </div>

        <!-- <transition name="fadepage" mode="out-in" appear>
            <Logo v-if="store.state.page==0"  @click.passive="goImpressum()"></Logo>
        </transition> -->

        <div class="deko__trenner"></div>

        <transition name="fadepage" mode="out-in" appear>
            <div class="deko__trenner1" v-if="store.state.impressum==false" :key="store.state.impressum"></div>
        </transition>
        
        <!-- <transition name="fadepage" mode="out-in" appear>
            <div class="deko__trenner2" v-if="store.state.page>0" :key="store.state.page>0"></div>
        </transition> -->

        <transition name="fadepage" mode="out-in" appear :key="store.state.page">
            <!-- <div class="deko__trenner2" v-if="store.state.page>=1 || store.state.kidsMenu==1" :key="store.state.page || store.state.kidsMenu==1"></div> -->
            <div class="deko__trenner2" v-if="store.state.page==1"  ></div>
        </transition>

    </footer>
    
</template>



<script>
// import {toRefs} from 'vue';
// import { onMounted } from 'vue';
import { onMounted } from 'vue';
import {useStore} from 'vuex'
import useEmitter from '@/composables/useEmitter'
import { useRouter } from 'vue-router'  // , useRoute
// import Logo from '../components/LogoAnimationTextLinks.vue';

export default {
  
  name: "GameFooter",
  // props: {
  //   contentui: Object,
  //   language: String
  // },

  components: {
    // Logo
  },  

  setup() { // props

    const router = useRouter();
    // const route = useRoute();

    const store = useStore();
    const emitter = useEmitter();
    
    const goHome = () => {
          console.log("goHome");
          store.state.page = 0;
          // store.state.book = -1;
          store.state.hideBerge = false;
          store.state.impressum = false;
          store.state.kidsBubble = true;
          store.state.kidsGames = false;
          store.state.kids = false;
          store.state.kidsMenu = -1;
          // router.push({ name: 'homepage',  props: true})  // params: { "bookID": ""}, 
          router.push({ path: "/homepage" + "" })
    }
    
    const goKidsMenu = () => {
        console.log("goKidsMenu");
        store.state.page = 1;
        store.state.hideBerge = false;
        store.state.impressum = false;
        store.state.kidsMenu = 1;
        store.state.kidsBubble = false;
        router.push({ path: "/kidsmenupage" + "" })
        // router.push({ name: 'intropage',  props: true} )  // params: { "bookID": "2"},
    }


    // const goKidsBubbleMenu = () => {
    //     console.log("goKidsBubbleMenu");
    //     store.state.page = 1;
    //     store.state.hideBerge = false;
    //     store.state.impressum = false;
    //     store.state.kidsMenu = -1;
    //     router.push({ path: "/kidsmenupage" + "/" })
    //     // router.push({ name: 'intropage',  props: true} )  // params: { "bookID": "2"},
    // }

    // const goIntro = () => {
    //     store.state.page = 1;
    //     store.state.hideBerge = false;
    //     store.state.impressum = false;
    //     router.push({ name: 'intropage',  props: true} )  // params: { "bookID": "2"},
    // }


    const goImpressum = () => {
        store.state.page = 1;
        // store.state.showGuide = false;
        store.state.hideBerge = true;
        store.state.impressum = true;
        store.state.kidsMenu = -1;
        store.state.kidsBubble = false;
        router.push({ name: 'impressumpage',  props: true} )  // params: { "bookID": "2"},
    }

    const goLanguage = (e) => {

      console.log(e.layerX);
      if (e.layerX < 38){
        store.state.language = "de"
      } else if (e.layerX >= 38  && e.layerX <= 75){
        store.state.language = "en"
      }  else {
        store.state.language = "nl"
      }
    }

    const showBerge = () => {
      if (store.state.hideBerge == false)  {
        return {
           marginLeft: '0px',
           marginTop: '-200px',
        }
      } else {
        return {
           marginLeft: '-95px',
           marginTop: '-70px',
        }
      }
    }

    const pfeilColor1 = () => {
      if (store.state.kids == true)  {
        return {
           borderColor: "#A3005F",
        }
      } else {
        return {
          borderColor: "#437E86",
        }
      }
    }


    const pfeilColor2 = () => {
      if (store.state.kids == true)  {
        return {
           borderRightColor: "#A3005F",
        }
      } else {
        return {
          borderRightColor: "#437E86",
        }
      }
    }

    const showArrow = () => {
      if (store.state.hideBerge == false)  {
        return {
         opacity: 0.0,
         transitionDelay: "0s",
        }
      } else {
        return {
          opacity: 1.0,
          transitionDelay: "0.5s",
        }
      }
    }

    const showVerein = () => {
      if (store.state.hideBerge == false)  {
        return {
         opacity: 1.0,
         transitionDelay: "0.6s",
        }
      } else {
        return {
          opacity: 0.0,
          transitionDelay: "0.0s",
        }
      }
    }

    const languagePos = () => {
      if (store.state.language == "de")  {
        return {
           left: '0px',
        }
      } else if (store.state.language == "en")  {
        return {
           left: '42px',
        }
      } else if (store.state.language == "nl")  {
        return {
           left: '85px',
        }
      }
    }

    onMounted(() => {
      // console.log("header UI content", props.contentui)
    })

    const kSImage = () => {
        return require('../assets/svgs/berge.svg');
        // return "../assets/svgs/berge.svg";
    }    

    const doHome = () => {
      emitter.emit("GO_HOME", "message from header" );
      console.log("goHome")
    }

    return {
      store,
      
      doHome,
      goHome,
      // goIntro,
      goKidsMenu,
      // goKidsBubbleMenu,
      goImpressum,
      kSImage,
      showBerge,
      languagePos,
      goLanguage,
      showArrow,
      showVerein,
      pfeilColor1,
      pfeilColor2,
    }
  },
}
</script>



<style lang="scss" scoped>
@import "../css/variables.scss";

.main-footer {
  position: absolute;
  width: 100%;
  // max-width: 1180px;
  height: 120px;
  // position: fixed;
  bottom: 0px;
  // left: 0;
  // background-color: #ffff00;
  // background: #1E211D;
  // padding: 0.5rem 2.0rem 0.5rem 2.05rem;
  // height: 7.0rem;
  // z-index: 50;
  display: flex;
  flex-direction: column;
  margin: auto;
  align-items: flex-start;
  justify-content: space-between;
  transition: top 0.3s; /* Transition effect when sliding down (and up) */
}

.deko__trenner {
  position: absolute;
  // background-color: red;
  top: 30px;
  right: 211px;
  height: 60px;
  width: 4px;
  // border-radius: 3px;
  border-right: 4px dotted $white;
}


.deko__trenner1 {
  position: absolute;
  // background-color: red;
  top: 30px;
  left: 1480px;
  height: 60px;
  width: 4px;
  // border-radius: 3px;
  border-right: 4px dotted $white;
}


.deko__trenner2 {
  position: absolute;
  // background-color: #ffffff;
  top: 30px;
  left: 867px;
  height: 60px;
  width: 3px;
  border-right: 4px dotted $white;
}
.deko__trenner3 {
  position: absolute;
  // background-color: #ffffff;
  top: 30px;
  left: 1170px;
  height: 60px;
  width: 3px;
  border-right: 4px dotted $white;
}

.flex-menu {
  position: absolute;
  left: 667px;
	bottom: 30px;
  display: flex;
  // background-color: red;
  // flex-direction: row-reverse;
  // flex-direction: row;
  // height: 50px;
}

.impressum-menu {
  position: absolute;
  // display: flex;
  // flex-direction: row;
  left: 1510px;
  bottom: 30px;
  // justify-content: space-between;
  // background-color: red;
  // padding: 0 20px;
}

.language-menu{
  position: absolute;
  // display: flex;
  // flex-direction: row;
  left: 1735px;
	bottom: 26px;
  // justify-content: space-between;
  // background-color: red;
  // padding: 0 20px;
}

.menu-btn {
  display: flex;
  flex-direction: column;
  // margin-right: 40px;
  // justify-content: space-between;
  // background-color: #94a78f41;
  // padding: 0 10px;
}

.lg-line {
  position: relative;
  top: -7px;
  // left: 0px;
  height: 5px;
  width: 30px;
  background-color: white;
  transition: all 0.5s ease;
}

.language-btn {
  display: flex;
  flex-direction: column;

  // margin-right: 120px;
  // justify-content: space-between;
  // background-color: #94a78f41;
  // padding: 0 20px;
}

.language-btn > p,
.impressum-btn > p,
.menu-btn > p {
  font-family: FontSerifItalic;
  font-size: 16px;
  line-height: 24px;
  margin-right: 15px;
  color: $whitetypo;
  // white-space:nowrap;
}

.language-btn h4,
.menu-btn h4,
.impressum-btn h4{
    font-family: FontRegular;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    font-weight: normal;
    // padding-top: 8px;
    padding-bottom: 10px;
    margin-bottom: 0px;
    color: white;
}

.language-btn > p {
  margin-top: 0px;
  margin-bottom: 5px;
}

.img-normal {
  margin-right: 25px;
  -webkit-transform: scaleX(1.0) scaleY(1.0);
  transform: scaleX(1.0) scaleY(1.0);
}



p.regular {
  font-family: FontNormal;
}
.img-mirror {
  margin-right: 0px;
  padding-bottom: 6px;
  height: 30px;
  width: 48px;
  -webkit-transform: scaleX(-1.0) scaleY(1.0);
  transform: scaleX(-1.0) scaleY(1.0);
  // background-color: aqua;
}

.main-header__brand {
  margin-left: -240px;
  margin-top: -360px;
  // background-color: orange;
  // height: 34px;
  // width: 120px;

  transition: all 0.5s ease-out;
  // width: auto;
  /* width: 20px; */
  position: relative;
}

.salzberg {
  -webkit-transform: scaleX(0.5) scaleY(0.5);
  transform: scaleX(0.5) scaleY(0.5) translate(-65%, -48%);
}

.verein {
  position: absolute;
  top: 120px;
  left: 90px;
  transition: 0.5s all ease;
}

.demo-arrow-cls {
  display: inline-block;
  position: absolute;
  width: 15px;
  height: 15px;
  background: transparent;
  text-indent: -9999px;
  border-bottom: 2px dotted $uparrow;
  border-right: 2px dotted $uparrow;
  transition: all .2s ease;
  text-decoration: none;
  color: transparent;
}
.demo-arrow-cls:hover {
  border-color: $uparrow;
  border-width: 2px;
}
.demo-arrow-cls:before {
  display: block;
  height: 200%;
  width: 200%;
  margin-left: -50%;
  margin-top: -50%;
  content: "";
  transform: rotate(45deg);
}
.demo-arrow-cls.up {
  transform: rotate(-135deg);
  left: 0;
}


.arrow {
  position: absolute;
  // left: 180px;
  // top: 70px;
  left: 150px;
  top: 60px;
  transition: all 0.5s ease;
  display: flex;
  flex-orientation: column;
}

.line-arrow {
  height: 32px;
  width: 2px;
  padding-left: 6px;
/*   border-color: #804040; */
/*   border-width: 8px; */
  border-right: 2px dotted $uparrow;
}

.arrow:hover {
  border-color: $uparrow;
  border-width: 2px;
}


.line {
  display:visible;
  background-color: #eaeee9;
  border-radius: 1px;
  height: 1px;
  width: 100%;
}

.main-nav {
  background-color: #ff1b68;
  // display: none;
  width: 100%
}

</style>